import React, { createContext, useContext } from 'react';
import useTenantStore from 'stores/tenantStore';

const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
    const tenantStore = useTenantStore();
    return (
        <TenantContext.Provider value={tenantStore}>
            {children}
        </TenantContext.Provider>
    );
};

export const useTenantContext = () => useContext(TenantContext);
