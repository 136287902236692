import {create} from "zustand";
const tenantStore = (set, get) => ({
    id: "",
    displayName: "",
    tenantUsers: [],
    tenantLogo: "",
    tenantSlogan: "",

    setTenant: (tenant) => {
        set((state) => ({
            id: tenant.id,
            displayName: tenant.displayName,
            tenantUsers: tenant.tenantUsers || [],
            tenantLogo: tenant.tenantLogo,
            tenantSlogan: tenant.tenantSlogan,
        }));
    },
});

const useTenantStore = create(tenantStore);

export default useTenantStore;