import React, {useCallback, useEffect, useState} from "react";
import { useAuth } from "react-oidc-context";
import PhoneInput from 'react-phone-number-input/input';
import {
  OverlayDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  tokens,
  makeResetStyles,
  Field,
  Input,
  Spinner, MessageBar, MessageBarBody,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import useMessageStore from "../stores/messageStore";
import {TenantApiClient} from "../providers/TenantApiClient";

const useStackClassName = makeResetStyles({
  display: "flex",
  flexDirection: "column",
  rowGap: tokens.spacingVerticalL,
});

const UserForm = ({onClose}) => {
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [memberInfo, setMemberInfo] = useState({firstName: '', lastName: '', email: '', mobilePhone: ''});
  const messageStore = useMessageStore();

  useEffect(() => {
    messageStore.clearMessages();
    TenantApiClient.get('/api/user')
      .then((data) => setMemberInfo(data))
      .catch((error) => messageStore.addAxiosError("Failed to return Member", error));
  }, []);

  const saveChanges = useCallback(() => {
    setErrors({})
    setIsLoading(true);
    messageStore.clearMessages();
    TenantApiClient.put('/api/user', memberInfo)
      .then((data) => {
        setMemberInfo(data);
        setIsLoading(false);
        messageStore.addMessage("Member Updated", "Your changes have been saved.")
        onClose()
      })
      .catch((error) => {
        const {errors} = error.response.data
        if (errors) {
          setErrors(errors)
        }
        setIsLoading(false);
        setErrors(errors => ({...errors, "": ["There were errors on your submission."]}))
      });
  }, [onClose, memberInfo])

  return (
    <form className={useStackClassName()}>
      {errors['']?.map(error => <MessageBar intent="error">
        <MessageBarBody>{error}</MessageBarBody>
      </MessageBar>)}
      <Field label="First Name" validationMessage={errors.FirstName?.join(' ')}>
        <Input
          type="text"
          value={memberInfo.firstName}
          onChange={e => setMemberInfo(m => ({...m, firstName: e.target.value}))}
        />
      </Field>
      <Field label="Last Name" validationMessage={errors.LastName?.join(' ')}>
        <Input
          type="text"
          value={memberInfo.lastName}
          onChange={e => setMemberInfo(m => ({...m, lastName: e.target.value}))}
        />
      </Field>
      <Field label="Email">
        <Input type="email" disabled readOnly value={memberInfo.email} />
      </Field>
      <Field label="Mobile Phone">
        <PhoneInput
          disabled readOnly
          inputComponent={Input}
          value={memberInfo.mobilePhone}
          onChange={value => setMemberInfo(m => ({...m, mobilePhone: value}))}
          defaultCountry="US"
        />
      </Field>
      <Field>
        <Button appearance="primary" onClick={saveChanges}>
          {isLoading && <Spinner size="tiny" />}
          Save Changes
        </Button>
      </Field>
    </form>
  )
}

export const UserProfile = ({isOpen, onClose}) => {
  const auth = useAuth();
  return (
    <OverlayDrawer
      as="aside"
      position="end"
      open={isOpen}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
            />
          }
        >
          User Profile
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <UserForm onClose={onClose} />
      </DrawerBody>
    </OverlayDrawer>
  )
};