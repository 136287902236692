import React from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    
});

const BillingAccountMember = () => {
    return (
        <p>Billing Account member coming soon</p>
    )
};

export default BillingAccountMember;