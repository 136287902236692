import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toolbar, ToolbarButton, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover, makeStyles } from "@fluentui/react-components";
import {
    ArrowSyncCircleRegular,
    EditRegular,
    CopyRegular,
    ContactCardLinkRegular,
    SendRegular
} from "@fluentui/react-icons";
import EditApplicationDialog from 'dialogs/EditApplication';
import TransactionChart from 'widgets/TransactionChart';
import FeaturesMenu from 'widgets/FeaturesMenu';
import DashboardTotals from 'widgets/DashboardTotals';
import LinkedAccounts from 'widgets/LinkedAccounts';
import Notifications from 'widgets/Notifications';
import { useTenantContext } from '../../tenantContext';
import backgroundImage from '../../assets/images/backgrounds/crm-dark.png';
import backgroundImage2 from '../../assets/images/backgrounds/crm-light.png';

const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        margin: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.2s ease',
    },
    transparentCell: {
        //backgroundColor: 'transparent'
        // or for partial transparency:
         backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
      menuContainer: {
        position: 'relative',
        display: 'inline-block'
    },
    menuPopover: {
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: 'white',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        borderRadius: '0.375rem',
        marginTop: '2px'
    }
});



const ActionButton = ({ icon: Icon, onClick, disabled, children }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`
            flex items-center px-4 py-2 text-sm font-medium
            ${disabled ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}
            transition-colors duration-200
            border border-gray-200 rounded-lg mr-2
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        `}
    >
        <Icon className="w-4 h-4 mr-2" />
        {children}
    </button>
);

const ApplicantDashboardPage = ({ application, onRequestRefresh }) => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const [showEditApplication, setEditApplication] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const tenantContext = useTenantContext();
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    // Memoize the invitation link to prevent unnecessary recalculations
    const invitationLink = useMemo(() => {
        return `${process.env.REACT_APP_APPLICANT_URL}?invitation=${application?.invitations?.[0]?.invitationCode || ''}`;
    }, [application?.invitations])

    // Initialize selected accounts from application data
    useEffect(() => {
        if (application?.primaryContact?.finicityLink?.accountIds) {
            setSelectedAccounts(application.primaryContact.finicityLink.accountIds);
        }
    }, [application]);

    const accountsData = useMemo(() => {
    if (!application?.primaryContact?.finicityLink?.accounts) {
        return [];
    }
    return application.primaryContact.finicityLink.accounts.map((account, index) => ({
        id: account.id || index,
        selector: false,
        bankName: account.bankName || 'N/A',
        accountName: account.accountName || 'N/A',
        accountNumber: account.accountNumber || 'N/A',
        syncAccount: 'Fetch',
        syncTransactions: 'Fetch',
        syncExtendedHistory: 'Fetch',
        transactions: 'Not Synced',
        extendedHistory: 'Not Enabled',
        statements: 'Not Synced'
    }));
}, [application]);


    const handleResendInvite = useCallback(async () => {
        try {
            await TenantApiClient.post(
                `/api/applications/${application.id}/ResendInvite/${application.invitations[0].invitationCode}`
            );
            messageStore.addMessage('Success', 'Invitation has been resent');
            onRequestRefresh?.();
        } catch (error) {
            messageStore.addAxiosError(error);
        }
    }, [application, messageStore, onRequestRefresh]);

    // Memoize handlers to prevent unnecessary re-renders
    const handleAccountSelection = useCallback((accounts) => {
        
        setSelectedAccounts(accounts);
    }, []);

    const handleDoneEditing = useCallback(() => {
        setEditApplication(false);
        onRequestRefresh?.();
    }, [onRequestRefresh]);

    const handleResyncAccounts = useCallback(async () => {
        if (!application?.primaryContact?.finicityLink || isSyncing) return;

        setIsSyncing(true);
        try {
            await TenantApiClient.post(
                `/api/finicity/syncaccounts/${application.id}/${application.primaryContact.contactId}`
            );
            onRequestRefresh?.();
        } catch (error) {
            messageStore.addAxiosError(error);
        } finally {
            setIsSyncing(false);
        }
    }, [classes, application, messageStore, onRequestRefresh, isSyncing]);

    return (
        <div className={classes.root}>
            <div className={classes.transparentCell}>
                <div className="space-y-6 p-6">
                    {/* Action Bar */}
                    <div className="bg-white shadow-sm rounded-lg p-2 flex flex-wrap items-center">
                        <Menu positioning={{ position: 'below', align: 'start' }}>
                            <MenuTrigger>
                                <div className={classes.menuButton}>
                                    <ActionButton icon={SendRegular}>
                                        Invitation
                                    </ActionButton>
                                </div>
                            </MenuTrigger>
                            <MenuPopover className={classes.menuPopover}>
                                <MenuList>
                                    <CopyToClipboard text={invitationLink}>
                                        <MenuItem icon={<CopyRegular />}>
                                            Copy Applicant Link
                                        </MenuItem>
                                    </CopyToClipboard>
                                    <Link to={invitationLink} target="_blank">
                                        <MenuItem icon={<ContactCardLinkRegular />}>
                                            Go To Applicant Link
                                        </MenuItem>
                                    </Link>
                                    <MenuItem icon={<SendRegular />} onClick={handleResendInvite}>
                                        Resend Invitation
                                    </MenuItem>
                                </MenuList>
                            </MenuPopover>
                        </Menu>

                        <ActionButton
                            icon={ArrowSyncCircleRegular}
                            onClick={onRequestRefresh}
                            disabled={isSyncing}
                        >
                            Refresh Accounts
                        </ActionButton>
                        <ActionButton
                            icon={EditRegular}
                            onClick={() => setEditApplication(true)}
                        >
                            Edit Application
                        </ActionButton>
                    </div>
                {/* Loading State */}
                {isSyncing && (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
                    </div>
                )}

                {/* Dashboard Content */}
                {!isSyncing && (
                    <div className="grid grid-cols-1 lg:grid-cols-11 gap-6">
                        <div className="lg:col-span-8 space-y-6">
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                    <LinkedAccounts
                                        accounts={accountsData}
                                        selectedAccounts={selectedAccounts}
                                        onAccountSelection={handleAccountSelection}
                                        onRequestRefresh={onRequestRefresh}
                                        application={application}
                                    />
                            </div>
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                <TransactionChart
                                    application={application}
                                    onAccountSelection={handleAccountSelection}
                                        selectedAccounts={selectedAccounts}
                                       
                                />
                            </div>
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                    <DashboardTotals
                                        application={application}
                                        selectedAccounts={selectedAccounts}
                                        onAccountSelection={handleAccountSelection}
                                    />
                            </div>
                        </div>
                        <div className="lg:col-span-3 space-y-6">
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                <FeaturesMenu />
                            </div>
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                <Notifications />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Dialogs */}
            <EditApplicationDialog
                application={application}
                showDialog={showEditApplication}
                onCancel={() => setEditApplication(false)}
                onComplete={handleDoneEditing}
            />
            </div>
        </div>
    );
};

export default ApplicantDashboardPage;