import React, { useState, useEffect } from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Select,
    Option,
    Field,
    Input,
    Button,
    ProgressBar,
    Divider,
    Text,
} from "@fluentui/react-components";
import { SendFilled } from "@fluentui/react-icons";
import { useTenantContext } from '../../src/tenantContext';

const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    sectionHeader: {
        margin: "20px 0 10px",
        fontWeight: "bold",
    },
    submitButton: {
        margin: "20px 0", // Adjusted margins to provide space
        display: "flex",
        justifyContent: "center", // Center the button content horizontally
        width: "100%", // Full width of the container
    }
});


const AssignApplicationDialog = ({ tenant, application, showDialog, onCancel, onComplete }) => {
    // State management
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(false);
    const [progressMessage, setProgressMessage] = useState(null);
    const [selectedUser, setSelectedUser] = useState("");
    const [tenantUsers, setTenantUsers] = useState([]);
    const tenantContext = useTenantContext();

    const classes = useAddClasses();

    // Form state
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 'Agent'
    });

    // Fetch tenant users on dialog open
    useEffect(() => {
        if (showDialog && tenantUsers.length === 0) {
            InternalApiClient.get(`/api/Tenants/${tenantContext.id}/members`)
                .then(response => {
                    setTenantUsers(response);
                })
                .catch(error => {
                    messageStore.addAxiosError("Failed to fetch tenant members", error);
                });
        }
    }, [showDialog, tenantContext.id]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle role selection
    const handleRoleChange = (e, data) => {
        setFormData(prev => ({
            ...prev,
            role: data.value
        }));
    };

    const handleAssignApplication = (e) => {
        e.preventDefault();

        if (!application?.id) {
            messageStore.addMessage("Application ID is missing or invalid.");
            return;
        }

        setIsBusy(true);
        setProgressMessage("Assigning application...");

        const request = {
            TenantId: tenantContext.id,
            MemberId: selectedUser,
        };

        TenantApiClient.put(`/api/Applications/${application.id}/assign`, request)
            .then(() => {
                setProgressMessage("Application assigned successfully!");
                messageStore.addMessage("Application assigned successfully!");
                if (onComplete) onComplete(true);
            })
            .catch(error => {
                messageStore.addAxiosError("Failed to assign application", error);
            })
            .finally(() => {
                setIsBusy(false);
            });
    };

    const handleInviteNewAgent = (e) => {
        e.preventDefault();

        const request = {
            Roles: [formData.role],
            Name: `${formData.firstName} ${formData.lastName}`,
            Email: formData.email,
            MobilePhone: formData.phone,
        };

        setIsBusy(true);
        setProgressMessage("Sending invitation...");

        InternalApiClient.post(`/api/Tenants/${tenantContext.id}/invite`, request)
            .then(() => {
                setProgressMessage("Invitation sent!");
                messageStore.addMessage("Invitation sent successfully!");
                if (onComplete) onComplete(true);
            })
            .catch(error => {
                messageStore.addAxiosError("Failed to send invitation", error);
            })
            .finally(() => {
                setIsBusy(false);
            });
    };
   
    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel(data.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Assign to Tenant Member</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {/* Assign to existing tenant member */}
                        <Field label="Select Member" required>
                            <Select
                                value={selectedUser}
                                onChange={(e, data) => setSelectedUser(data.value)}
                                placeholder="Select a member"
                            >

                                {tenantUsers.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </Select>
                        </Field>
                        <Button
                            type="button"
                            icon={<SendFilled />}
                            appearance="primary"
                            onClick={handleAssignApplication}
                            disabled={!selectedUser}
                        >
                            Assign Application
                        </Button>

                        <Divider />

                        {/* Invite new member form */}
                        <form onSubmit={handleInviteNewAgent}>
                            <DialogTitle>Invite Member to Tenant</DialogTitle>
                            <div className="space-y-4">
                                <Field required label="Role">
                                    <Select
                                        value={formData.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="Owner">Owner</option>
                                        <option value="Agent">Agent</option>
                                    </Select>
                                </Field>
                                <Field required label="First Name">
                                    <Input
                                        required
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                </Field>
                                <Field required label="Last Name">
                                    <Input
                                        required
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                </Field>
                                <Field required label="Email Address">
                                    <Input
                                        required
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </Field>
                                <Field label="Mobile Phone">
                                    <Input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                    />
                                </Field>
                                <Button
                                    type="submit" // Since it's in a form and should submit the form
                                    icon={<SendFilled />}
                                    appearance="primary"
                                    className={classes.submitButton} // Using the updated class
                                >
                                    Send Invitation
                                </Button>
                              
                            </div>
                        </form>

                        <Divider />

                        {progressMessage && <Text>{progressMessage}</Text>}
                        {isBusy && <ProgressBar />}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default AssignApplicationDialog;