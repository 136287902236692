import React, {useState} from 'react';

import AdminNav from './Nav';
import {Card, makeStyles} from "@fluentui/react-components";
import ActivityLog from "./Tabs/ActivityLog";
import Notifications from "./Tabs/Notifications";

const useStyles = makeStyles({
  card: {
    margin: "20px",
    maxWidth: "900px",
  },
});

function getPageContent(pageIndex) {
    console.log('get page');
    console.log(pageIndex);
    switch (pageIndex) {
      case "Activity Log": return <ActivityLog />;
      case "Personal Notifications": return <Notifications />;
    }
}

const AdminMainPage = () => {
  const [currentPage, setCurrentPage] = useState("User Preferences");
  const styles = useStyles();

  return (
    <div className="twoColumnContainer">
      <nav className="nav">
        <AdminNav currentPage={currentPage} setCurrentPage={(page) => setCurrentPage(page)} />
      </nav>
      <div className="body">
        <Card className={styles.card}>
          {getPageContent(currentPage)}
        </Card>
      </div>
    </div>
  )
};

export default AdminMainPage;