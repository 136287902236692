import {create} from "zustand";

const messageStore = (set, get) => ({
    messages: [],
    messageCount: 0,
    addMessage: (title, message, intent = "success") => {
        const id = get().messageCount + 1
        set((state) => ({
            messageCount: state.messageCount + 1,
            messages: [...state.messages, {...message, title: title, message: message, id: id, intent: intent}]
        }));
        setTimeout(() => set((state) => ({
            messageCount: state.messageCount - 1,
            messages: state.messages.filter(m => m.id !== id)
        })), 3000)
    },
    addAxiosError: (title, error) => {
        if (error?.response?.data && error?.response?.data?.title) {
            set((state) => ({
                messageCount: state.messageCount + 1,
                messages: [...state.messages, {...error, title: error.response?.data?.title, message: error.response?.data?.detail, id: state.messageCount + 1, intent: "error"}]
            }));
        } else if (error?.message) {
            set((state) => ({
                messageCount: state.messageCount + 1,
                messages: [...state.messages, {...error, title: title, message: error.message, id: state.messageCount + 1, intent: "error"}]
            }));
        } else {
            set((state) => ({
                messageCount: state.messageCount + 1,
                messages: [...state.messages, {...error, title: title, message: error, id: state.messageCount + 1, intent: "error"}]
            }));
        }
    },
    addApolloError: (title, error) => {
        if (error && error.message) {
            let msg = error.message;
            if (error.extensions) {
                msg = error.extensions.message;
            }

            set((state) => ({
                messageCount: state.messageCount + 1,
                messages: [...state.messages, {...error, title: title, message: msg, id: state.messageCount+ 1, intent: "error"}]
            }));
        }
    },
    clearMessage: (id) => {
        const messages = get().messages;
        const updatedMessages = messages?.map((message) => {
            if (message.id !== id) {
                return message;
            }
        });
        set((state) => ({
            messages: updatedMessages,
            messageCount: state.messageCount - 1,
        }));
    },
    clearMessages: () => {
        set({
            messages: [],
            messageCount: 0,
        })
    },
});

const useMessageStore = create(messageStore);

export default useMessageStore;