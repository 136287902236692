import React, {useState, useEffect} from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
    Field,
    Checkbox,
    Button,
    Caption1,
    Text,
    Card,
    CardHeader,
    Spinner,
  } from "@fluentui/react-components";

import {
    CheckmarkCircle24Filled,
} from "@fluentui/react-icons";

  const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
  })

const HistoricSyncDialog = ({application, contact, showDialog, onCancel, onComplete}) =>{
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [accounts, setAccounts] = useState(null);

    const fetchApplication = () => {
        messageStore.clearMessages();
        TenantApiClient.get(`api/applications/finicityaccounts/${application.id}/${contact.contactId}`)
            .then((data) => {
                var unSyncedAccounts = data.filter(item => !item.isSynced);
                Object.keys(unSyncedAccounts).forEach(function (key){
                    Object.defineProperty(unSyncedAccounts[key], "IsSyncing", {value: false});
                    Object.defineProperty(unSyncedAccounts[key], "IsChecked", {value: true});
                });
                setAccounts(unSyncedAccounts);
            })
            .catch((error) => {
                messageStore.addAxiosError("Failed to return Finicity Accounts", error);
            });
    }

    useEffect(() => {
        fetchApplication();
    }, []);

    const handleSyncAccounts = () => {
        console.log("sync accounts");
        var updatedAccounts = accounts.map((account) => {
            account.isSyncing = true;
            return account;
        });
        setAccounts(updatedAccounts);

        //setOpenAccountSync(false);
    }

    const handleCheckChanged = (account) => {
        var updatedAccounts = accounts.map((item) => {
            if (item.id === account.id) {
                item.isSyncing = true;
            }
            return item;
        });
        console.log(updatedAccounts);
        setAccounts(updatedAccounts);

        TenantApiClient.get(`/api/applications/syncfinicityhistorictransactions/${application.id}/${contact.contactId}/${account.id}`)
        .then((data) => {
            var updatedAccounts = accounts.map((item) => {
                if (item.id === account.id) {
                    item.isSynced = true;
                }
                return item;
            });
            setAccounts(updatedAccounts);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const applyControl = (account) => {
        if (account.isSynced) {
            return <CheckmarkCircle24Filled />
        } else {
            if (account.isSyncing) {
                return <Spinner/>;
            } else {
                return <Checkbox onChange={() => handleCheckChanged(account)} checked={account.isChecked} />
            }
        }
    };

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel()}>
            <DialogSurface>
            <form onSubmit={handleSyncAccounts}>
                <DialogBody>
                    <DialogTitle>Enable Extended History Transactions</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Caption1>This will enable extended transaction history for these accounts.  Note: there is an additional charge for this action.</Caption1>
                        {accounts && accounts.map((account) => (
                            <Card
                                key={account.id}
                                className={classes.card}
                                floatingAction={
                                    applyControl(account)
                                }
                            >
                                <CardHeader
                                    header={<Text weight="semibold">{account.name}</Text>}
                                    description={
                                    <Caption1 className={classes.caption}>
                                        {"**********" + account.accountNumberDisplay}
                                    </Caption1>
                                    }
                                />
                            </Card>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </form>
            </DialogSurface>
        </Dialog>
    )
}

export default HistoricSyncDialog;