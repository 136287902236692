import * as React from "react";
import { useLocation, NavLink } from "react-router-dom";
import {
    ContactCardRegular,
    WindowWrenchFilled,
} from "@fluentui/react-icons";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Toolbar, ToolbarGroup, Button } from "@fluentui/react-components";
import logo from '../assets/images/vixo-link-logo.png';

const useStyles = makeStyles({
    toolbar: {
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        width: '72px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 0',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    },
    logoContainer: {
        padding: '4px 12px 16px 12px',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoButton: {
        padding: '8px',
        minWidth: '36px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        transition: 'transform 0.2s ease',

        ':hover': {
            backgroundColor: 'transparent',
            transform: 'scale(1.05)',
        },
        ':active': {
            transform: 'scale(0.95)',
        }
    },
    logoImage: {
        width: '32px',
        height: '32px',
        objectFit: 'contain',
    },
    navGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        width: '100%',
    },
    navLink: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '12px 0',
        gap: '4px',
        position: 'relative',
        transition: 'all 0.2s ease',

        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2,

            '& .icon': {
                color: tokens.colorBrandBackground,
            },

            '& .label': {
                color: tokens.colorNeutralForeground1,
            }
        },

        '&.active': {
            backgroundColor: tokens.colorNeutralBackground2,

            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: '8px',
                bottom: '8px',
                width: '3px',
                backgroundColor: tokens.colorBrandBackground,
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
            },

            '& .icon': {
                color: tokens.colorBrandBackground,
            },

            '& .label': {
                color: tokens.colorNeutralForeground1,
            }
        }
    },
    icon: {
        fontSize: '24px',
        color: tokens.colorNeutralForeground3,
        transition: 'color 0.2s ease',
    },
    label: {
        fontSize: '12px',
        lineHeight: '16px',
        color: tokens.colorNeutralForeground3,
        transition: 'color 0.2s ease',
        textAlign: 'center',
    },
    divider: {
        margin: '8px 16px',
        height: '1px',
        backgroundColor: tokens.colorNeutralStroke2,
    }
});

const AppIcon = () => {
    const styles = useStyles();

    return (
        <div className={styles.logoContainer}>
            <Button
                className={styles.logoButton}
                onClick={() => window.location.href = '/'}
            >
                <img src={logo} className={styles.logoImage} alt="Vixo Link Logo" />
            </Button>
        </div>
    );
};

export const AppBar = () => {
    const styles = useStyles();
    const location = useLocation();

    return (
        <Toolbar vertical className={styles.toolbar}>
            <AppIcon />
            <ToolbarGroup className={styles.navGroup}>
                <NavLink
                    to="/"
                    className={({ isActive }) =>
                        `${styles.navLink} ${isActive ? 'active' : ''}`
                    }
                >
                    <ContactCardRegular className={`icon ${styles.icon}`} />
                    <span className={`label ${styles.label}`}>Applicants</span>
                </NavLink>

                <div className={styles.divider} />

                <NavLink
                    to="/Admin"
                    className={({ isActive }) =>
                        `${styles.navLink} ${isActive ? 'active' : ''}`
                    }
                >
                    <WindowWrenchFilled className={`icon ${styles.icon}`} />
                    <span className={`label ${styles.label}`}>Admin</span>
                </NavLink>
            </ToolbarGroup>
        </Toolbar>
    );
};