import React, { useState } from 'react';
import { makeStyles, Button, tokens } from "@fluentui/react-components";
import { Widget } from 'components/Widget';
import {
    AppsList24Filled,
    ArrowSyncFilled,
    SendFilled,
    CalendarFilled,
    AttachFilled,
    DocumentSignatureRegular,
    WalletCreditCardRegular,
    HistoryRegular,
    DocumentBulletListClockRegular,
    ContactCardGroupRegular
} from "@fluentui/react-icons";
import {
    MenuList,
    Menu,
    MenuItem,
  } from "@fluentui/react-components";

import RequestFollowUpDialog from 'dialogs/RequestFollowUp';
import TranactionSyncDialog from "dialogs/TransactionSync";
import HistoricSyncDialog from 'dialogs/HistoricSync';
import AddContactDialog from 'dialogs/AddContact';

const useClasses = makeStyles({
    widget: {
        margin: "0px",
        padding: "0px",
        minHeight: "300px",
    },
    menuItemList: {
        width: "100%",
    },
    menuItem: {
        maxWidth: "100% !important",
    },
});

const FeaturesMenu = ({application, onRequestRefresh}) => {
    const classes = useClasses();

    return (
        <>
        <div className="flex flex-col col-span-full sm:col-span-6 sm:row-span-2 xl:col-span-3 shadow-sm rounded-xl">
        <Widget image={<AppsList24Filled />} title="Features" widgetClass={classes.widget}>
            <MenuList className={classes.menuItemList}>
                <MenuItem className={classes.menuItem} disabled={true} icon={<DocumentSignatureRegular />}>DocuSign</MenuItem>
                <MenuItem className={classes.menuItem} disabled={true} icon={<WalletCreditCardRegular />}>Credit</MenuItem>
                <MenuItem className={classes.menuItem} disabled={true} icon={<HistoryRegular/>}>Background</MenuItem>
                <MenuItem className={classes.menuItem} disabled={true} icon={<DocumentBulletListClockRegular />}>Transcripts</MenuItem>
                <MenuItem className={classes.menuItem} disabled={true} icon={<ContactCardGroupRegular />}>ID Verification</MenuItem>
            </MenuList>
        </Widget>
        </div>
        </>
    )
};

export default FeaturesMenu;