import React, {useState, useEffect} from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Combobox,
    Option,
    Persona,
    Button,
    ProgressBar,
    Text,
    Divider,
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Spinner,
    Caption1,
  } from "@fluentui/react-components";

import {
    Dismiss24Regular,
    CheckmarkCircle24Regular,
    CalendarArrowDownFilled
} from "@fluentui/react-icons";

const useDateRangeSelectClasses = makeStyles({
    dialog: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    dialogButton: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
});

const DateRangeSelectDialog = ({startDate, endDate, onSelect, showDialog, onClose}) =>{
    const classes = useDateRangeSelectClasses();
    const [state, setState] = useState([
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection'
        }
      ]);

    const handleOpenChange = (data) => {
        setState([{
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: 'selection'
        }]);

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog open={showDialog} onOpenChange={handleOpenChange}>
            <DialogSurface className={classes.dialog}>
                <DialogBody>
                    <DialogTitle action={
                        <DialogTrigger action="close">
                            <Button
                            appearance="subtle"
                            aria-label="close"
                            icon={<Dismiss24Regular />}
                            />
                        </DialogTrigger>}>
                        Select Date Range</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onSelect(state[0].startDate, state[0].endDate)} appearance="primary">Select</Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={onClose} className={classes.dialogButton} appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
)};

export default DateRangeSelectDialog;