import React, {useState, useCallback} from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
    Field,
    Checkbox,
    Button,
    ProgressBar,
    Text,
    Divider,
  } from "@fluentui/react-components";
import {
    SendFilled,
} from "@fluentui/react-icons";
import PhoneInput from 'react-phone-number-input/input';

const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
})

// Note: this is a temporary structure because the tenant todo item customization feature isn't done
const TodoItems = [
    {
        definitionId: "ContactInfo",
        name: "Contact Information",
        isRequired: true,
        isChecked: true,
    },
    {
        definitionId: "Finicity",
        name: "Finicity Account Link",
        isRequired: false,
        isChecked: true,
    },
    {
        definitionId: "Plaid",
        name: "Plaid Account Link",
        isRequired: false,
        isChecked: false,
    }
];

const CreateApplicationDialog = ({showDialog, onCancelDialog, onComplete}) =>{
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [todoItems, setTodoItems] = useState(TodoItems);
    const [isBusy, setIsBusy] = useState(false);
    const [phone, setPhone] = useState('')

    const handleTodoCheck = (ev, todoItem) => {
        let updatedItems = todoItems.map((item) => {
            if (item.definitionId === todoItem.definitionId) {
                item.isChecked = ev.target.checked;
            }
            return item;
        });

        setTodoItems(updatedItems);
    };


  
    const handleAddApplication = useCallback((ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        var loanType = "Business";
        const newApplication = {
            firstName: formProps.firstName,
            lastName: formProps.lastName,
            email: formProps.email,
            mobilePhone: phone,
            applicationType: loanType,
        };
        
        const selectedItems = todoItems.map((item) => {
            if (item && item.isChecked === true) {
                return item.definitionId;
            }
        }).filter(val => val != undefined);

        setIsBusy(true);
        setProgressMessage("Progress: Adding Application");
        TenantApiClient.post("/api/applications", newApplication)
        .then((data) => {
            setProgressMessage("Progress: Sending Invitation");

            const request = {
                applicationId: data.id,
                contactId: data.contacts[0].contactId,
                TodoItems: selectedItems,
            };

            TenantApiClient.post('api/applications/invite', request)
            .then((data) => {
                if (onComplete) {
                    onComplete(data.id);
                }
            })
            .catch((error) => {
                console.error(error);
                messageStore.addAxiosError("Failed to Send Invitation", error);
                onCancelDialog();
            });
        })
        .catch((error) => {
            console.error(error);
            messageStore.addAxiosError("Failed to Create Application", error);
            onCancelDialog();
        });
    }, [phone]);

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancelDialog(data.open)}>
            <DialogSurface>
            <form onSubmit={handleAddApplication}>
            <DialogBody>
                <DialogTitle>Send New Invitation</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Field required placeholder="First Name" label="First Name">
                        <Input required type="text" name="firstName" id={"firstName"} />
                    </Field>
                    <Field required placeholder="Last Name" label="Last Name">
                        <Input required type="text" name="lastName" id={"lastName"} />
                    </Field>
                    <Field required label="Email Address">
                        <Input required type="email" name="email" id={"email"} />
                    </Field>
                    <Field label="Mobile Phone">
                        <PhoneInput
                          id={"phone"}
                          inputComponent={Input}
                          value={phone}
                          onChange={setPhone}
                          defaultCountry="US"
                        />
                    </Field>
                    <br/>
                    <Label>Application Items</Label>
                    <Field hint="Which Applicaiton Items are you requesting?">
                        {todoItems.map((todoItem) => (
                            <Checkbox key={todoItem.definitionId} name={todoItem.definitionId} id={todoItem.definitionId} 
                                checked={todoItem.isChecked} disabled={todoItem.isRequired} label={todoItem.name}
                                onChange={(event) => handleTodoCheck(event, todoItem)} />
                        ))}
                    </Field>
                    <br />
                    <Divider/>

                    <Text>{progressMessage}</Text>
                    {isBusy && 
                        <ProgressBar />
                    }
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button disabled={isBusy} appearance="secondary">Cancel</Button>
                    </DialogTrigger>
                    <Button type="submit" disabled={isBusy} icon={<SendFilled />} appearance="primary">
                        Send Invitation
                    </Button>
                </DialogActions>
            </DialogBody>
            </form>
            </DialogSurface>
        </Dialog>
    )
}

export default CreateApplicationDialog;