import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Button, tokens } from "@fluentui/react-components";
import { Card, CardHeader, CardPreview, Text, Caption1 } from "@fluentui/react-components";

const useClassesShortcut = makeStyles({

    icon64: 
    { 
        fontSize: "48px",
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    card: {
        width: "300px",
    },

    link: {
        textDecoration: "none",
    },
});

const ShortcutCard = ({title, description, icon, linkTo, onClick}) => {
    const classes = useClassesShortcut();

    return (
        <Link to={linkTo} className={classes.link} onClick={onClick}>
            <Card className={classes.card}>
                <CardHeader 
                    image={icon} 
                    header={<Text weight="semibold">{title}</Text>}
                    description={<Caption1 className={classes.caption}>{description}</Caption1>} />
            </Card>
        </Link>
    )
}

export default ShortcutCard;