import React from 'react';
import {
    Hamburger,
    NavCategory,
    NavCategoryItem,
    NavDrawer,
    NavDrawerBody,
    NavDrawerHeader,
    NavDrawerProps,
    NavItem,
    NavSectionHeader,
    NavSubItem,
    NavSubItemGroup,
    AppItem,
  } from "@fluentui/react-nav-preview";
  import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

  import {
    Label,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    useId,
  } from "@fluentui/react-components";

  import {
    Board20Filled,
    Board20Regular,
    BoxMultiple20Filled,
    BoxMultiple20Regular,
    DataArea20Filled,
    DataArea20Regular,
    DocumentBulletListMultiple20Filled,
    DocumentBulletListMultiple20Regular,
    HeartPulse20Filled,
    HeartPulse20Regular,
    MegaphoneLoud20Filled,
    MegaphoneLoud20Regular,
    NotePin20Filled,
    NotePin20Regular,
    People20Filled,
    People20Regular,
    PeopleStar20Filled,
    PeopleStar20Regular,
    Person20Filled,
    PersonLightbulb20Filled,
    PersonLightbulb20Regular,
    Person20Regular,
    PersonSearch20Filled,
    PersonSearch20Regular,
    PreviewLink20Filled,
    PreviewLink20Regular,
    bundleIcon,
    PersonCircle32Regular,
    PersonCircle24Regular,
  } from "@fluentui/react-icons";

const Person = bundleIcon(Person20Filled, Person20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Announcements = bundleIcon(MegaphoneLoud20Filled, MegaphoneLoud20Regular);
const EmployeeSpotlight = bundleIcon(
  PersonLightbulb20Filled,
  PersonLightbulb20Regular
);
const Search = bundleIcon(PersonSearch20Filled, PersonSearch20Regular);
const PerformanceReviews = bundleIcon(
  PreviewLink20Filled,
  PreviewLink20Regular
);
const JobPostings = bundleIcon(NotePin20Filled, NotePin20Regular);
const Interviews = bundleIcon(People20Filled, People20Regular);
const HealthPlans = bundleIcon(HeartPulse20Filled, HeartPulse20Regular);
const TrainingPrograms = bundleIcon(BoxMultiple20Filled, BoxMultiple20Regular);
const CareerDevelopment = bundleIcon(PeopleStar20Filled, PeopleStar20Regular);
const Analytics = bundleIcon(DataArea20Filled, DataArea20Regular);
const Reports = bundleIcon(
  DocumentBulletListMultiple20Filled,
  DocumentBulletListMultiple20Regular
);

const useClasses = makeStyles({

    drawer: {
        height: "100vh",
    }
  });

const FinicityNav = ({currentPage, setCurrentPage}) => {
    const classes = useClasses();
    function onSelect(event, data) {
      setCurrentPage(data.value);
    }

    return (
        <NavDrawer
        className={classes.drawer}
        defaultSelectedValue={currentPage}
        open={true}
        type={"inline"}
        size={"medium"}
        onNavItemSelect={onSelect}
      >
        <NavDrawerHeader>
          <Label>Finicity Menu</Label>
        </NavDrawerHeader>
        <NavDrawerBody>
          <NavItem icon={<Dashboard />} value="Main">
            Dashboard
          </NavItem>
          <NavItem icon={<Search />} value="Customers">
            Customers
          </NavItem>
        </NavDrawerBody>
      </NavDrawer>
    )
};

export default FinicityNav;