import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { makeStyles, shorthands, tokens, useClasses } from "@fluentui/react-components";
import useMessageStore from 'stores/messageStore';
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup } from "@fluentui/react-components";
import { SearchBox, Tag, TagGroup, TagGroupProps } from '@fluentui/react-components';
import { subDays } from 'date-fns';
import { Spinner } from "@fluentui/react-components";
import useAccountStore from 'stores/accountStore';

import {
    CalendarArrowDownFilled,
    WindowArrowUpRegular,
    AttachFilled,
    ArrowDownloadFilled,
    ArrowSyncCircleRegular,
    SearchFilled,
    CalendarSearchRegular,
} from "@fluentui/react-icons";

import {
    PresenceBadgeStatus,
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridProps,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
} from "@fluentui/react-components";
import { renderHeaderCell } from 'react-data-grid';

import DateRangeSelectDialog from 'dialogs/DateRangeSelect';
import { date } from 'yup';


const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        margin: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.2s ease',
    },
    headerBar: {
        height: "48px",
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px',
        gap: '12px'
    },
    headerButton: {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)'
        }
    },
    searchBox: {
        minWidth: '300px',
        '& input': {
            borderRadius: '8px',
            transition: 'all 0.2s ease',
            '&:focus': {
                borderColor: tokens.colorBrandBackground,
                boxShadow: `0 0 0 2px ${tokens.colorBrandBackground}20`
            }
        }
    },
    dataGrid: {
        backgroundColor: 'transparent',
        minHeight: "calc(100vh - 205px)",
        maxHeight: "calc(100vh - 205px)",
        overflowY: "auto",
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '& [role="row"]': {
            transition: 'background-color 0.2s ease',
            '&:hover': {
                backgroundColor: 'rgba(0, 120, 212, 0.05)'
            }
        }
    },
    gridHeader: {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
        '& [role="columnheader"]': {
            fontWeight: '600',
            color: tokens.colorNeutralForeground1
        }
    },
    tagGroup: {
        padding: '8px 0',
        gap: '8px',
        flexWrap: 'wrap'
    },
    tag: {
        borderRadius: '16px',
        backgroundColor: 'rgba(0, 120, 212, 0.1)',
        color: tokens.colorBrandForeground1,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    dateRangeButton: {
        backgroundColor: 'rgba(0, 120, 212, 0.08)',
        borderRadius: '8px',
        padding: '6px 12px',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    spinner: {
        position: "absolute",
        zIndex: "200",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
    },
    cellAmount: {
        fontFamily: 'monospace',
        fontWeight: '500',
        '&[data-negative="true"]': {
            color: tokens.colorPaletteRedForeground1
        },
        '&[data-positive="true"]': {
            color: tokens.colorPaletteGreenForeground1
        }
    },
    toolbarGroup: {
        gap: '8px',
        alignItems: 'center'
    }
});


const getColumns = (classes) => [
    createTableColumn({
        columnId: "bankName",
        compare: (a, b) => {
            console.log(b)
            return a.accountId.localeCompare(b.accountId);
        },
        renderHeaderCell: () => {
            return "Bank Name"
        },
        renderCell: (item) => {
            return "";
        }
    }),
    createTableColumn({
        columnId: "account",
        compare: (a, b) => {
            return a.accountNumberDisplay.localeCompare(b.accountNumberDisplay);
        },
        renderHeaderCell: () => {
            return "Account"
        },
        renderCell: (item) => {
            return item.accountNumberDisplay;
        }
    }),
    createTableColumn({
        columnId: "source",
        compare: (a, b) => {
            return a.source.localeCompare(b.source);
        },
        renderHeaderCell: () => {
            return "Source"
        },
        renderCell: (item) => {
            return item.source;
        }
    }),
    createTableColumn({
        columnId: "category",
        compare: (a, b) => {
            return (a.category || '').localeCompare(b.category || '');
        },
        renderHeaderCell: () => {
            return "Category"
        },
        renderCell: (item) => {
            return item.category;
        }
    }),
    createTableColumn({
        columnId: "transactionDate",
        compare: (a, b) => {
            return a.transactionDate.localeCompare(b.transactionDate);
        },
        renderHeaderCell: () => {
            return "Date"
        },
        renderCell: (item) => {
            return new Date(item.transactionDate).toLocaleDateString();
        }
    }),
    createTableColumn({
        columnId: "Description",
        compare: (a, b) => {
            return a.description.localeCompare(b.description);
        },
        renderHeaderCell: () => {
            return "Description"
        },
        renderCell: (item) => {
            return item.description;
        }
    }),
    createTableColumn({
        columnId: "Amount",
        compare: (a, b) => a.amount < b.amount ? -1 : 1,
        renderHeaderCell: () => "Amount",
        renderCell: (item) => {
            const amount = item.amount;
            return (
                <TableCellLayout>
                    <span
                        className={classes.cellAmount}
                        data-negative={amount < 0}
                        data-positive={amount > 0}
                    >
                        {amount.toLocaleString("en-us", {
                            style: "currency",
                            currency: "USD"
                        })}
                    </span>
                </TableCellLayout>
            )
        }
    })
];

const columnSizingOptions = {
    Description: {
        defaultWidth: 200,
        minWidth: 160,
        idealWidth: 200,
    },
};

const useClassesTransactions = makeStyles({
    root: {

    },
    container: {
        backgroundColor: tokens.colorNeutralBackground1,
        padding: "20px",
        minHeight: "100vh",
    },
    headerButton: {
        marginRight: "5px",
        backgroundColor: tokens.colorNeutralBackground4,
    },
    headerBar:
    {
        height: "42px",
        backgroundColor: tokens.colorNeutralBackground4,
        justifyContent: 'flex-start',
        borderTop: "1px solid silver",
        justifyContent: "space-between",
    },
    dataGrid:
    {
        backgroundColor: tokens.colorNeutralBackground1,
        minHeight: "calc(100vh - 205px)",
        maxHeight: "calc(100vh - 205px)",
        overflowY: "scroll"
    },
    spinner: {
        position: "absolute",
        zIndex: "200",
        left: "0",
        right: "0",
        top: "250px",
    },
});

export const GET_ACCOUNTTRANSACTIONS = gql`
  query getAccountTransactions(
    $applicationId: String!, 
    $contactId: String!, 
    $startDate: DateTime!, 
    $endDate: DateTime!,
    $accountIds: [String!]  # Add accountIds parameter
  ) {
    transactions(
      applicationId: $applicationId, 
      contactId: $contactId, 
      startDate: $startDate, 
      endDate: $endDate,
      accountIds: $accountIds  # Include accountIds in transactions query
    ) {
      accountId
      accountNumberDisplay
      accountName
      transactionId
      category
      payeeName
      description
      type
      transactionDate
      amount
      checkNumber
      source
    }
  }
`;


const TransactionsPage = ({ application, onRequestRefresh }) => {
    const classes = useStyles();
    const { selectedAccounts } = useAccountStore();
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(true);
    const [visibleTags, setVisibleTags] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
    const [startDate, setStartDate] = useState(subDays(new Date(), 90));
    const [endDate, setEndDate] = useState(new Date());
    const [transactions, setTransactions] = useState([]);

    const { loading, error, data, refetch } = useQuery(GET_ACCOUNTTRANSACTIONS, {
        variables: { applicationId: application.id, contactId: application.primaryContact.contactId, startDate, endDate, accountIds: selectedAccounts },
        onCompleted: data => {
           
            setTransactions(data.transactions);
            setIsBusy(false);
        },
    });



    const handleRefresh = async () => {
        setIsBusy(true);
        await refetch();
        applySearch(data.transactions, visibleTags);
        setIsBusy(false);
    }

    const applySearch = (fetchedTransactions, tags) => {
        let filteredTransactions = fetchedTransactions;
        tags.forEach((tag) => {
            console.log('filtering for ' + tag.text);
            filteredTransactions = filteredTransactions.filter(item =>
                item.description.toLowerCase().includes(tag.text.toLowerCase()));
        });
        setTransactions(filteredTransactions);
    }

    const handleSearchButton = () => {
        let updatedTags = [...visibleTags, { value: visibleTags.length + 1, text: searchText }];
        setVisibleTags(updatedTags);
        applySearch(data.transactions, updatedTags);
    }

    const removeItem = (event, { value }) => {
        let updatedTags = [...visibleTags].filter((tag) => tag.value !== value);
        setVisibleTags(updatedTags);
        applySearch(data.transactions, updatedTags);
    };

    const handleDateRangeSelect = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setShowDateRangeDialog(false);
    }

    return (
        <div className={classes.root}>
            <DateRangeSelectDialog
                startDate={startDate}
                endDate={endDate}
                showDialog={showDateRangeDialog}
                onClose={() => setShowDateRangeDialog(false)}
                onSelect={handleDateRangeSelect}
            />

            <Toolbar className={classes.headerBar}>
                <ToolbarGroup className={classes.toolbarGroup}>
                    <SearchBox
                        placeholder="Search transactions..."
                        size="medium"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        className={classes.searchBox}
                    />
                    <ToolbarButton
                        icon={<SearchFilled />}
                        onClick={handleSearchButton}
                        className={classes.headerButton}
                    >
                        Search
                    </ToolbarButton>
                </ToolbarGroup>

                <ToolbarGroup className={classes.toolbarGroup}>
                    <ToolbarButton
                        icon={<CalendarSearchRegular />}
                        onClick={() => setShowDateRangeDialog(true)}
                        className={classes.dateRangeButton}
                    >
                        {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </ToolbarButton>
                    <ToolbarButton
                        icon={<ArrowSyncCircleRegular />}
                        onClick={handleRefresh}
                        className={classes.headerButton}
                    >
                        Refresh
                    </ToolbarButton>
                </ToolbarGroup>
            </Toolbar>

            {visibleTags.length > 0 && (
                <TagGroup
                    onDismiss={removeItem}
                    aria-label="Active filters"
                    className={classes.tagGroup}
                >
                    {visibleTags.map((tag, index) => (
                        <Tag
                            dismissible
                            key={index}
                            value={tag.value}
                            className={classes.tag}
                        >
                            {tag.text}
                        </Tag>
                    ))}
                </TagGroup>
            )}

            <DataGrid
                className={classes.dataGrid}
                items={transactions}
                columnSizingOptions={columnSizingOptions}
                columns={getColumns(classes)}
                sortable
                getRowId={(item) => item.id}
                focusMode="composite"
            >
                {isBusy && (
                    <div className={classes.spinner}>
                        <Spinner label="Loading transactions..." />
                    </div>
                )}
                <DataGridHeader className={classes.gridHeader}>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>
                                {renderHeaderCell()}
                            </DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => (
                        <DataGridRow key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>
                                    {renderCell(item)}
                                </DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </div>
    );
};

export default TransactionsPage;