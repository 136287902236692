import React, {useState, useEffect, useCallback} from "react";
import { Buffer } from "buffer";
import { Document, Page } from 'react-pdf';
import { makeStyles, Button, tokens } from "@fluentui/react-components";
import { TenantApiClient } from "providers/TenantApiClient";
import { Toolbar, ToolbarButton, Caption1 } from "@fluentui/react-components";

import {
    DocumentTextLinkRegular
} from "@fluentui/react-icons";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;

const useClasses = makeStyles({
    image: {
        border: "1px solid black",
    },
    pdfContainer: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    pdfDocument: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: tokens.colorNeutralBackground4,
        marginBottom: "40px",
        paddingBottom: "40px",
        paddingTop: "10px",
    },
    pdfPage: {
        backgroundColor: tokens.colorNeutralBackground4,
        alignItems: "center",
        "& .react-pdf__Page__canvas": {
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
        },
    },
    headerButton: { 
        marginRight:"5px", 
        backgroundColor: tokens.colorNeutralBackground4,
    },
    headerBar: 
    {
        height:"42px", 
        backgroundColor: tokens.colorNeutralBackground4, 
        justifyContent: 'flex-start',
        borderTop: "1px solid silver",
        borderBottom: "1px solid silver",
    },
});

export const DocumentThumbnail = ({id, props}) => {
    const classes = useClasses();
    const [imgSrc, setImgSrc] = useState(null);

    useEffect(() => {
        TenantApiClient.getFile('/api/documents/thumbnail/' + id)
            .then((response) => {
                setImgSrc(URL.createObjectURL(response.data));
            })
    }, []);

    return (
        imgSrc !== null ?
            <img width="60px" className={classes.image} src={imgSrc} {...props} /> : <Caption1>Loading Thumbnail</Caption1>
        
)};

export const DocumentViewer = ({id, title, onClose}) => {
    const classes = useClasses();
    const [docSrc, setDocSrc] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    useEffect(() => {
        TenantApiClient.getFile('/api/documents/document/' + id)
            .then((response) => {
                setDocSrc(URL.createObjectURL(response.data));
            })
    }, []);

    const onResize = useCallback((entries) => {
        const [entry] = entries;
    
        if (entry) {
          setContainerWidth(entry.contentRect.width);
        }
      }, []);

    
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
      }

    return(
        <div>
            <Toolbar aria-label="Vertical" size="Large" className={classes.headerBar}>
                <ToolbarButton icon={<DocumentTextLinkRegular />} onClick={onClose}>Back to Statements</ToolbarButton>
            </Toolbar>
            <div className="PDF-container-document" ref={setContainerRef}>
                <Document className={classes.pdfDocument} file={docSrc} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                    {Array.from(new Array(numPages), (_el, index) => (
                    <Page
                        className={classes.pdfPage}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                    />
                    ))}
            </Document>
            <p></p>
          </div>
        </div>
)};