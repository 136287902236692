import React, { useMemo, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter } from 'recharts';
import { useQuery, gql } from '@apollo/client';
import { Widget } from 'components/Widget';
import { ArrowTrendingLinesRegular } from "@fluentui/react-icons";
import { makeStyles, tokens, ToggleButton } from "@fluentui/react-components";
import  accountStore  from 'stores/accountStore';
import { GET_ACCOUNT_BALANCES } from './queries';
import { startOfDay, subDays } from 'date-fns';


const useStyles = makeStyles({
    wrapper: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 40px rgba(0, 0, 0, 0.12)'
        }
    },
    chartContainer: {
        width: '100%',
        height: '500px',
        padding: '20px'
    },
    loadingState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        color: tokens.colorNeutralForeground2,
        fontSize: '1.1rem'
    },
    errorState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        color: tokens.colorPaletteRedForeground1,
        padding: '24px',
        textAlign: 'center'
    },
    filterContainer: {
        display: 'flex',
        gap: '8px',
        marginBottom: '16px',
        padding: '0 20px'
    },
    menuButton: {
        minWidth: 'auto',
        padding: '4px',
    },
    filterButton: {
        minWidth: '80px'
    }
});

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                background: 'rgba(255, 255, 255, 0.95)',
                padding: '12px',
                border: '1px solid #eee',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
            }}>
                <p style={{ margin: '0 0 8px', fontWeight: 'bold' }}>
                    {new Date(label).toLocaleDateString()}
                </p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ margin: '4px 0', color: entry.color }}>
                        {`${entry.name}: $${entry.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const CHART_COLORS = [
    '#3B82F6', // Blue
    '#10B981', // Green
    '#F59E0B', // Yellow
    '#EF4444', // Red
    '#8B5CF6', // Purple
    '#EC4899', // Pink
    '#14B8A6', // Teal
    '#F97316'  // Orange
];

const GET_TRANSACTION_TOTALS = gql`
  query getTransactionTotals($applicationId: String!, $contactId: String!, $accountIds: [String!]) {
    transactionTotals(applicationId: $applicationId, contactId: $contactId, accountIds: $accountIds) {
      totalTransactions
      totalDeposits
      totalSpending
      totalPayments
      averageDailyBalance
      daysWithNegativeBalance
      
      monthlyTotals {               
        totalTransactions
        totalDeposits
        totalSpending
        totalPayments
        averageDailyBalance
        daysWithNegativeBalance
        
        dailyBalances {           
          date
          balance
        }
      }

      dailyBalances {
        accountId 
        date
        balance
      }
    }
  }
`;


const TransactionChart = ({ application }) => {
    const classes = useStyles();
    const [timeRange, setTimeRange] = useState(30);
    const { selectedAccounts } = accountStore(); 

    const { data, loading, error } = useQuery(GET_TRANSACTION_TOTALS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId,
            accountIds: selectedAccounts,
            startDate: calculateStartDate(timeRange),
            endDate: new Date()
        },
        skip: !selectedAccounts || selectedAccounts.length === 0,
    });

    const processedData = useMemo(() => {
        if (!data?.transactionTotals?.dailyBalances || !data.transactionTotals.dailyBalances.length) {
            return [];
        }

        // Get the cutoff date based on current date
        const now = new Date();
        const startDate = new Date(now);
        startDate.setHours(0, 0, 0, 0);
        const cutoffDate = new Date(startDate);
        cutoffDate.setDate(cutoffDate.getDate() - timeRange);

        // First, get all dates within range and sort them
        const dateAccountMap = new Map();

        data.transactionTotals.dailyBalances.forEach(({ date, balance, accountId }) => {
            const transactionDate = new Date(date);
            if (transactionDate >= cutoffDate && transactionDate <= startDate) {
                if (!dateAccountMap.has(date)) {
                    dateAccountMap.set(date, {
                        date,
                    });
                }
                const dateEntry = dateAccountMap.get(date);
                dateEntry[accountId] = balance;
            }
        });

        const sortedData = Array.from(dateAccountMap.values())
            .sort((a, b) => new Date(a.date) - new Date(b.date));

        // Ensure we have entries for all days in the range
        const allDates = [];
        const currentDate = new Date(cutoffDate);

        while (currentDate <= startDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            allDates.push(dateStr);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Fill in any missing dates with null values
        const filledData = allDates.map(date => {
            const existingData = sortedData.find(d => d.date.includes(date)) || { date };
            return existingData;
        });

        return filledData;
    }, [data, timeRange]);

    const accountIds = useMemo(() => {
        if (!data?.transactionTotals?.dailyBalances) return [];
        return [...new Set(data.transactionTotals.dailyBalances.map(b => b.accountId))];
    }, [data]);

    const renderWidget = (content) => (
        <div className={classes.wrapper}>
            <Widget title="Account Daily Balances" image={<ArrowTrendingLinesRegular />}>
                <div className={classes.filterContainer}>
                    <ToggleButton
                        appearance={timeRange === 30 ? "primary" : "secondary"}
                        className={classes.filterButton}
                        onClick={() => setTimeRange(30)}
                    >
                        30 Days
                    </ToggleButton>
                    <ToggleButton
                        appearance={timeRange === 60 ? "primary" : "secondary"}
                        className={classes.filterButton}
                        onClick={() => setTimeRange(60)}
                    >
                        60 Days
                    </ToggleButton>
                    <ToggleButton
                        appearance={timeRange === 90 ? "primary" : "secondary"}
                        className={classes.filterButton}
                        onClick={() => setTimeRange(90)}
                    >
                        90 Days
                    </ToggleButton>
                    <ToggleButton
                        appearance={timeRange === 180 ? "primary" : "secondary"}
                        className={classes.filterButton}
                        onClick={() => setTimeRange(180)}
                    >
                       180 Days
                    </ToggleButton>
                </div>
                {content}
            </Widget>
        </div>
    );

    if (loading) return renderWidget(
        <div className={classes.loadingState}>Loading account data...</div>
    );

    if (error) return renderWidget(
        <div className={classes.errorState}>Error loading data: {error.message}</div>
    );

    if (!processedData.length) return renderWidget(
        <div className={classes.loadingState}>No data available for selected accounts</div>
    );

    return renderWidget(
        <div className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={processedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                >
                    <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="rgba(0,0,0,0.1)"
                        vertical={false}
                    />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date) => new Date(date).toLocaleDateString()}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        tick={{ fontSize: 12 }}
                    />
                    <YAxis
                        tickFormatter={(value) => `$${value.toLocaleString()}`}
                        tick={{ fontSize: 12 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        verticalAlign="top"
                        height={36}
                        wrapperStyle={{
                            paddingBottom: '20px',
                            fontSize: '12px'
                        }}
                    />
                    {accountIds.map((accountId, index) => (
                        <React.Fragment key={accountId}>
                            <Line
                                type="monotone"
                                dataKey={accountId}
                                name={`Account ${accountId.slice(-4)}`}
                                stroke={CHART_COLORS[index % CHART_COLORS.length]}
                                strokeWidth={2}
                                dot={false}
                                activeDot={{ r: 8 }}
                                connectNulls={true}
                            />
                            <Scatter
                                dataKey={accountId}
                                fill={CHART_COLORS[index % CHART_COLORS.length]}
                                line={false}
                                shape="circle"
                                r={4}
                            />
                        </React.Fragment>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};


// Helper function to calculate start date based on time range
const calculateStartDate = (timeRange) => {
    const date = new Date();
    date.setDate(date.getDate() - timeRange);
    return date;
};

export default TransactionChart;