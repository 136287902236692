import React from 'react';
import {
    NavDrawer,
    NavDrawerBody,
    NavItem,
    NavSectionHeader,
  } from "@fluentui/react-nav-preview";
import { makeStyles } from "@fluentui/react-components";
import {
  Alert20Filled,
  Alert20Regular,
  DataBarVertical20Filled,
  DataBarVertical20Regular,
  Document20Filled,
  Document20Regular,
  DocumentText20Filled,
  DocumentText20Regular,
  List20Filled,
  List20Regular,
  LockShield20Filled,
  LockShield20Regular,
  Mail20Filled,
  Mail20Regular,
  MoneyCalculator20Filled,
  MoneyCalculator20Regular,
  Payment20Filled,
  Payment20Regular,
  People20Filled,
  People20Regular,
  PeopleAdd20Filled,
  PeopleAdd20Regular,
  PeopleSettings20Filled,
  PeopleSettings20Regular,
  Person20Filled,
  Person20Regular,
  Settings20Filled,
  Settings20Regular,
  Tag20Filled,
  Tag20Regular,
  bundleIcon,
} from "@fluentui/react-icons";

const AlertIcon = bundleIcon(Alert20Filled, Alert20Regular);
const DataBarIcon = bundleIcon(DataBarVertical20Filled, DataBarVertical20Regular);
const DocumentIcon = bundleIcon(Document20Filled, Document20Regular);
const DocumentTextIcon = bundleIcon(DocumentText20Filled, DocumentText20Regular);
const ListIcon = bundleIcon(List20Filled, List20Regular);
const LockShieldIcon = bundleIcon(LockShield20Filled, LockShield20Regular);
const MailIcon = bundleIcon(Mail20Filled, Mail20Regular);
const MoneyCalculatorIcon = bundleIcon(MoneyCalculator20Filled, MoneyCalculator20Regular);
const PaymentIcon = bundleIcon(Payment20Filled, Payment20Regular);
const PersonIcon = bundleIcon(Person20Filled, Person20Regular);
const PeopleIcon = bundleIcon(People20Filled, People20Regular);
const PeopleAddIcon = bundleIcon(PeopleAdd20Filled, PeopleAdd20Regular);
const PeopleSettingsIcon = bundleIcon(PeopleSettings20Filled, PeopleSettings20Regular);
const SettingsIcon = bundleIcon(Settings20Filled, Settings20Regular);
const TagIcon = bundleIcon(Tag20Filled, Tag20Regular);

const useClasses = makeStyles({
  drawer: {
    height: "100vh",
  }
});

const AdminNav = ({currentPage, setCurrentPage}) => {
  const classes = useClasses();
  function onSelect(event, data) {
    setCurrentPage(data.value);
  }

  return (
      <NavDrawer
      className={classes.drawer}
      defaultSelectedValue={currentPage}
      open={true}
      type={"inline"}
      size={"medium"}
      onNavItemSelect={onSelect}
    >
      <NavDrawerBody>
        <NavSectionHeader>Personal Settings</NavSectionHeader>
        <NavItem icon={<LockShieldIcon />} value="Security">Security</NavItem>
        <NavItem icon={<AlertIcon />} value="Personal Notifications">Notifications</NavItem>
        <NavItem icon={<SettingsIcon />} value="User Preferences">User Preferences</NavItem>

        <NavSectionHeader>Lender Settings</NavSectionHeader>
        <NavItem icon={<PeopleIcon />} value="Users">Users</NavItem>
        <NavItem icon={<TagIcon />} value="Branding">Branding</NavItem>
        <NavItem icon={<PeopleSettingsIcon />} value="Applicant Configuration">Applicant Configuration</NavItem>
        <NavItem icon={<AlertIcon />} value="Lender Notifications">Notifications</NavItem>
        <NavItem icon={<MailIcon />} value="Email Templates">Email Templates</NavItem>
        <NavItem icon={<DocumentIcon />} value="Company Documents">Company Documents</NavItem>
        <NavItem icon={<PaymentIcon />} value="Payments">Payments</NavItem>
        <NavItem icon={<DataBarIcon />} value="Analytics">Analytics</NavItem>
        <NavItem icon={<PeopleAddIcon />} value="Bulk Applicants Import">Bulk Applicants Import</NavItem>
        
        <NavSectionHeader>Billing</NavSectionHeader>
        <NavItem icon={<DocumentTextIcon />} value="Billing">Billing</NavItem>
        <NavItem icon={<MoneyCalculatorIcon />} value="Pricing">Pricing</NavItem>
        <NavItem icon={<ListIcon />} value="Activity Log">Activity Log</NavItem>
      </NavDrawerBody>
    </NavDrawer>
  )
};

export default AdminNav;