import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import useMessageStore from 'stores/messageStore';
import { DocumentThumbnail, DocumentViewer } from 'components/Document';
import { TenantApiClient } from 'providers/TenantApiClient';
import {

    CalendarArrowDownFilled,
    AttachFilled,
    ArrowDownloadFilled,
    ArrowSyncCircleRegular,
    DocumentPdfRegular,
    DocumentRegular,
    ChevronDownRegular,
    ChevronUpRegular,
    CheckmarkCircle24Filled
} from "@fluentui/react-icons";
import {
    createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow,
    makeStyles, SearchBox,
    Spinner, TableCellLayout, Tag, TagGroup,
    tokens,
    Toolbar,
    ToolbarButton,
    ToolbarGroup
} from "@fluentui/react-components";



import FetchStatementsDialog from 'dialogs/FetchStatements';
import UploadPDFStatementsDialog from 'dialogs/UploadPDFStatements';

const GET_ACCOUNT_STATEMENTS = gql`
query getAccountStatements($applicationId: String!, $contactId: String!) {
    accounts(applicationId: $applicationId, contactId: $contactId) {
        source,
        accountId,
        accountNumberDisplay,
        accountName,
        institutionId,
        institutionName,
        institutionLogo,
        institutionIcon,
        isTransactionSynced,
        isHistoricTransactionsEnabled,
        isStatementsSynced,
        isVisible,
        uploadedStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        },
        plaidStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        },
        bankStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        }
    }
}
`;


const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        padding: '24px',
        margin: '20px',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        minHeight: 'calc(100vh - 40px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },

    thumbnail: {
        width: '100%',
        paddingBottom: '75%', // Changed to 4:3 aspect ratio for smaller height
        position: 'relative',
        background: tokens.colorNeutralBackground3,
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden',
        maxHeight: '180px' // Added max height constraint
    },
    thumbnailContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Changed to contain to prevent cropping
            padding: '8px' // Added padding to prevent image from touching edges
        },
        '& svg': {
            width: '30%', // Reduced icon size
            height: '30%',
            color: tokens.colorNeutralForeground3
        }
    },
    documentCard: {
        background: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        maxWidth: '250px', // Added max width to keep cards from getting too wide
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
        }
    },
    actionBar: {
        display: 'flex',
        gap: '12px',
        padding: '16px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        flexWrap: 'wrap'
    },
    actionButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'white',
        transition: 'all 0.2s ease',
        color: tokens.colorNeutralForeground1,
        '&:hover:not(:disabled)': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
        },
        '&:disabled': {
            backgroundColor: tokens.colorNeutralBackground4,
            color: tokens.colorNeutralForeground4,
            cursor: 'not-allowed'
        }
    },
    accountSection: {
        background: 'white',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        transition: 'box-shadow 0.2s ease',
        '&:hover': {
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
        }
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 20px',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.05)'
        }
    },
    accountInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },
    accountIcon: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground3
    },
    accountDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px'
    },
    accountTitle: {
        fontSize: '1rem',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1
    },
    accountSubtitle: {
        fontSize: '0.875rem',
        color: tokens.colorNeutralForeground2
    },
    documentsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '16px',
        padding: '20px',
        background: tokens.colorNeutralBackground2
    },
    documentCard: {
        background: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
        }
    },
    //thumbnail: {
    //    aspectRatio: '3/4',
    //    background: tokens.colorNeutralBackground3,
    //    display: 'flex',
    //    alignItems: 'center',
    //    justifyContent: 'center'
    //},
    documentInfo: {
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    documentTitle: {
        fontSize: '0.875rem',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
        lineHeight: '1.2',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    },
    documentStatus: {
        fontSize: '0.75rem',
        color: tokens.colorNeutralForeground2
    },
    documentActions: {
        padding: '12px',
        borderTop: `1px solid ${tokens.colorNeutralBackground4}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    actionButtonPrimary: {
        width: '100%',
        padding: '6px 12px',
        borderRadius: '6px',
        fontSize: '0.875rem',
        fontWeight: '500',
        backgroundColor: tokens.colorBrandBackground,
        color: 'white',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover
        }
    },
    actionButtonSecondary: {
        width: '100%',
        padding: '6px 12px',
        borderRadius: '6px',
        fontSize: '0.875rem',
        fontWeight: '500',
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorBrandForeground1,
        border: `1px solid ${tokens.colorBrandStroke1}`,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackground2
        }
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        color: tokens.colorBrandForeground1
    }

});

const STORAGE_KEY = 'ocrolusDocumentStatus';
const ActionButton = ({ icon: Icon, onClick, disabled, children }) => {
    const classes = useStyles();
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={classes.actionButton}
        >
            <Icon />
            {children}
        </button>
    );
};

const StatementCard = ({ application, statement, onClick, ocrolusStatus = {}, onUseOcrolus, onFetchResults, onCheckStatus, isUploadedDocument }) => {
    const classes = useStyles();

    // Check if statement and application are defined before proceeding
    if (!statement || !application) return null;

    const currentStatus = statement.documentStorageId ? ocrolusStatus[statement.documentStorageId] || null : null;
    const applicationStatus = ocrolusStatus[application?.id] || (application?.ocrolusBookStatus ?? null);

    const showOcrolusButton = isUploadedDocument &&
        (!applicationStatus || applicationStatus === null || applicationStatus === 'Failed');

    const showCheckStatusButton = isUploadedDocument &&
        (ocrolusStatus[statement.documentStorageId] === 'Uploaded' || applicationStatus === 'Verifying');

    // Updated this condition to match the response format
    const showFetchButton = isUploadedDocument &&
        (applicationStatus === 'VERIFIED' || applicationStatus === 'COMPLETE');

    const showSyncedStatus = applicationStatus === 'Synced';

    return (
        <div className={classes.documentCard}>
            <div onClick={onClick}>
                <div className={classes.thumbnail}>
                    <div className={classes.thumbnailContent}>
                        {statement.documentStorageId ? (
                            <DocumentThumbnail
                                id={statement.documentStorageId}
                                alt={statement.description || 'Document thumbnail'}
                            />
                        ) : (
                            <DocumentRegular />
                        )}
                    </div>
                </div>
                <div className={classes.documentInfo}>
                    <div className={classes.documentTitle}>
                        {statement.description || 'Untitled Document'}
                    </div>
                    {currentStatus && (
                        <div className={classes.documentStatus}>
                            Status: {currentStatus}
                        </div>
                    )}
                </div>
            </div>

            {isUploadedDocument && statement.documentStorageId && (
                <div className={classes.documentActions}>
                    {showOcrolusButton && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onUseOcrolus(statement);
                            }}
                            className={classes.actionButtonPrimary}
                        >
                            Use Ocrolus
                        </button>
                    )}
                    {showCheckStatusButton && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onCheckStatus(statement);
                            }}
                            className={classes.actionButtonSecondary}
                        >
                            Check Status
                        </button>
                    )}
                    {showFetchButton && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onFetchResults(statement);
                            }}
                            className={classes.actionButtonSecondary}
                        >
                            Fetch Results
                        </button>
                    )}
                    {showSyncedStatus && (
                        <div className="flex items-center justify-center p-2">
                            <CheckmarkCircle24Filled className="text-green-500 w-6 h-6" />
                            <span className="ml-2 text-green-500">Synced</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};


const UploadedDocumentSection = ({ application, account, onViewDocument, onUseOcrolus, onFetchResults, onCheckStatus, ocrolusStatus }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const documentCount = account.bankStatements?.length || 0;

    return (
        <div className="bg-white rounded-xl shadow-sm mb-6 overflow-hidden">
            <div
                className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="flex items-center space-x-3">
                    <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                        <DocumentRegular className="w-5 h-5 text-gray-600" />
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">
                            Uploaded Documents
                        </h3>
                        <p className="text-sm text-gray-500">
                            {documentCount} {documentCount === 1 ? 'document' : 'documents'} uploaded
                        </p>
                    </div>
                </div>
                {isExpanded ?
                    <ChevronUpRegular className="w-5 h-5 text-gray-400" /> :
                    <ChevronDownRegular className="w-5 h-5 text-gray-400" />
                }
            </div>

            {isExpanded && (
                <div className="p-4 bg-gray-50">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {account.bankStatements && account.bankStatements.map(statement => (
                            <StatementCard
                                key={statement.id}
                                application={application}
                                statement={statement}
                                onClick={() => onViewDocument(statement)}
                                onUseOcrolus={onUseOcrolus}
                                onFetchResults={onFetchResults}
                                onCheckStatus={onCheckStatus}
                                ocrolusStatus={ocrolusStatus}
                                isUploadedDocument={true}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const LinkedAccountSection = ({ account, onViewDocument }) => {
    const [isExpanded, setIsExpanded] = useState(true);


    // Get the appropriate statements array based on source
    const statements = account.source === 'Plaid' ? account.plaidStatements : account.uploadedStatements;



    return (
        <div className="bg-white rounded-xl shadow-sm mb-6 overflow-hidden">
            <div
                className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="flex items-center space-x-3">
                    {account.institutionIcon ? (
                        <img
                            src={account.institutionIcon}
                            alt={account.institutionName}
                            className="w-8 h-8 rounded-full"
                        />
                    ) : (
                        <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                            <DocumentRegular className="w-5 h-5 text-gray-600" />
                        </div>
                    )}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900">
                            {account.institutionName || 'Bank Account'}
                        </h3>
                        <p className="text-sm text-gray-500">
                            {account.accountName}
                            {account.accountNumberDisplay && ` � ${account.accountNumberDisplay}`}
                        </p>
                    </div>
                </div>
                {isExpanded ?
                    <ChevronUpRegular className="w-5 h-5 text-gray-400" /> :
                    <ChevronDownRegular className="w-5 h-5 text-gray-400" />
                }
            </div>

            {isExpanded && (
                <div className="p-4 bg-gray-50">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {statements && statements.map(statement => (
                            <StatementCard
                                key={statement.id}
                                statement={statement}
                                onClick={() => onViewDocument(statement)}
                                isUploadedDocument={false}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const StatementsPage = ({ application, onRequestRefresh, selectedAccounts, onAccountSelection }) => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const [accounts, setAccounts] = useState(null);
    const [showFetchStatements, setFetchStatements] = useState(false);
    const [showUploadPdfStatements, setUploadPdfStatements] = useState(false);
    const [viewDocument, setViewDocument] = useState(false);
    const [currentDocId, setCurrentDocId] = useState(null);

    // Initialize ocrolusStatus from application instead of localStorage
    const [ocrolusStatus, setOcrolusStatus] = useState(() => {
        // If application has OcrolusBookStatus, use it as initial state
        if (application?.OcrolusBookStatus) {
            return {
                [application.id]: application.OcrolusBookStatus
            };
        }
        return {};
    });

    // Save ocrolusStatus changes to localStorage
    useEffect(() => {
        if (application?.OcrolusBookStatus) {
            setOcrolusStatus(prev => ({
                ...prev,
                [application.id]: application.OcrolusBookStatus
            }));
        }
    }, [application?.OcrolusBookStatus]);


    const { loading, error, data, refetch } = useQuery(GET_ACCOUNT_STATEMENTS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId
        },
        onCompleted: (data) => {
            if (data?.accounts) {
                // Keep accounts as they come from the API without additional processing
                setAccounts(data.accounts.filter(item => item.isVisible));
            }
        },
    });

    if (error) {
        messageStore.addApolloError(error);
    }

    const handleRefresh = async () => {
        try {
            await refetch();
            messageStore.addMessage('Statements Freshed');
        } catch (error) {
            console.log(error)
            messageStore.addAxiosError('Failed to refresh statements');

        }
    };

    const handleUseOcrolus = async (statement) => {
        if (!statement.documentStorageId) {
            messageStore.addError('Document ID is missing');
            return;
        }

        const uploadId = encodeURIComponent(statement.documentStorageId);

        try {
            await TenantApiClient.post(`/api/documents/Ocrolus/${application.id}/${application.contacts[0].contactId}/${uploadId}`);

            // Immediately update application status to 'Verifying' to show check status button
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: 'Uploaded',
                [application.id]: 'Verifying'
            }));

            await refetch();

        } catch (error) {
            console.error('Ocrolus API call failed:', error);
            messageStore.addAxiosError('Error processing document', error);
        }
    };


    const handlecheckBookStatus = async (statement) => {
        try {
            const response = await TenantApiClient.get(`/api/documents/Ocrolus/${application.id}/status`);
            if (response?.response?.book_status) {
                // Update status based on the response book_status
                setOcrolusStatus(prev => ({
                    ...prev,
                    [application.id]: response.response.book_status
                }));

                // If status is VERIFIED, show the fetch button
                if (response.response.book_status === 'VERIFIED') {
                    setOcrolusStatus(prev => ({
                        ...prev,
                        [statement.documentStorageId]: 'Ready',
                        [application.id]: 'VERIFIED'
                    }));
                }
            }
        } catch (error) {
            messageStore.addAxiosError('Error checking status', error);
        }
    };
const handleFetchResults = async (statement) => {
    try {
        const response = await TenantApiClient.get(`/api/documents/Ocrolus/${application.id}/transactions`);
        console.log('Results fetched:', response);

        // Update the Ocrolus status to 'COMPLETE' to show the "Synced" status
        setOcrolusStatus(prev => ({
            ...prev,
            [statement.documentStorageId]: 'COMPLETE',
            [application.id]: 'COMPLETE'
        }));

    } catch (error) {
        messageStore.addAxiosError('Error fetching results', error);
    }
};


    const handleViewDocument = (statement) => {
        setCurrentDocId(statement.documentStorageId);
        setViewDocument(true);
    };

    return (
        <div className={classes.root}>
            {viewDocument && (
                <DocumentViewer
                    id={currentDocId}
                    onClose={() => setViewDocument(false)}
                />
            )}

            <div className={viewDocument ? 'hidden' : ''}>
                <div className={classes.actionBar}>
                    <ActionButton
                        icon={CalendarArrowDownFilled}
                        onClick={() => setFetchStatements(true)}
                    >
                        Fetch Statements
                    </ActionButton>
                    <ActionButton
                        icon={DocumentPdfRegular}
                        onClick={() => setUploadPdfStatements(true)}
                    >
                        Upload PDF
                    </ActionButton>
                    <ActionButton
                        icon={AttachFilled}
                        disabled
                    >
                        Attach Documents
                    </ActionButton>
                    <ActionButton
                        icon={ArrowDownloadFilled}
                        disabled
                    >
                        Download All
                    </ActionButton>
                    <ActionButton
                        icon={ArrowSyncCircleRegular}
                        onClick={handleRefresh}
                    >
                        Refresh
                    </ActionButton>
                </div>

                {loading ? (
                    <div className={classes.spinner}>
                        <Spinner size="large" />
                    </div>
                ) : (
                    <div className={classes.content}>
                        {accounts?.filter(account => account.source === "Upload")
                            .map(account => (
                                <UploadedDocumentSection
                                    key={account.accountId}
                                    application={application} // Pass application here
                                    account={account}
                                    onViewDocument={handleViewDocument}
                                    onUseOcrolus={handleUseOcrolus}
                                    onFetchResults={handleFetchResults}
                                    onCheckStatus={handlecheckBookStatus}
                                    ocrolusStatus={ocrolusStatus}
                                />
                            ))}

                        {accounts?.filter(account => account.source !== "Upload")
                            .map(account => (
                                <LinkedAccountSection
                                    key={account.accountId}
                                    account={account}
                                    onViewDocument={handleViewDocument}
                                />
                            ))}
                    </div>
                )}

                <FetchStatementsDialog
                    application={application}
                    accounts={accounts || []} // Ensure accounts is never null
                    showDialog={showFetchStatements}
                    onClose={() => {
                        setFetchStatements(false);
                        handleRefresh();
                    }}
                />
                <UploadPDFStatementsDialog
                    application={application}
                    showDialog={showUploadPdfStatements}
                    onClose={() => {
                        setUploadPdfStatements(false);
                        handleRefresh();
                    }}
                />
            </div>
        </div>
    );
};

export default StatementsPage;