import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { useQuery, gql } from '@apollo/client';
import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { Tab, TabList, Button, Text } from "@fluentui/react-components";
import useAccountStore from 'stores/accountStore';
import {
    BoardFilled,
    BoardRegular,
    DocumentBulletListFilled,
    DocumentBulletListRegular,
    DocumentTextLinkFilled,
    DocumentTextLinkRegular,
    bundleIcon,
    ChartMultipleRegular,
    ChartMultipleFilled,
    Briefcase24Regular,
    Money24Regular,
    Calendar24Regular,
} from "@fluentui/react-icons";
import ApplicantDashboardPage from './Dashboard';
import TransactionsPage from './Transactions';
import StatementsPage from './Statements';
import CashFlow from "./CashFlow";

const Dashboard = bundleIcon(BoardFilled, BoardRegular);
const Transactions = bundleIcon(DocumentBulletListFilled, DocumentBulletListRegular);
const Statements = bundleIcon(DocumentTextLinkFilled, DocumentTextLinkRegular);
const ChartMultipleIcon = bundleIcon(ChartMultipleFilled, ChartMultipleRegular);

const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        margin: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.2s ease',
        minHeight: '100vh'  // Corrected property name and value
    },
    headerBar: {
        height: "48px",
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px',
        gap: '12px'
    },
    headerButton: {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)'
        }
    },
    content: {
        flex: "1",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gridRowGap: tokens.spacingVerticalXXL,
        gridAutoRows: "max-content",
    },
    panels: {
        height: "calc(100vh - 205px)",
    },
    inActive: {
        display: "none",
    },
})


const GET_ACCOUNTTRANSACTIONS = gql`
  query GetSelectedAccountsTransactionsAsync($applicationId: String!, $contactId: String!, $startDate: DateTime!, $endDate: DateTime!) {
    transactions(applicationId: $applicationId, contactId: $contactId, startDate: $startDate, endDate: $endDate) {
      accountId,
      accountNumberDisplay,
      accountName,
      transactionId,
      category,
      payeeName,
      description,
      type,
      transactionDate,
      amount,
      checkNumber,
      source
    }
  }
`;

const ApplicantPage = () => {
    const { selectedAccounts, setSelectedAccounts, initializeFromApplication } = useAccountStore();
    const classes = useStyles();
    const { id } = useParams();
    const [application, setApplication] = useState(null);
    const messageStore = useMessageStore();
    const [selectedValue, setSelectedValue] = useState("dashboard");


    const onTabSelect = (event, data) => {
        setSelectedValue(data.value);
    };

    const fetchApplication = () => {  // Remove selectedAccounts parameter
        messageStore.clearMessages();
        TenantApiClient.get('api/applications/' + id)
            .then((data) => {
                setApplication(data);

                // Instead of directly setting accounts, use the store's initialize function
                // This will only set accounts if none are currently selected
                if (selectedAccounts.length === 0) {
                    initializeFromApplication(data);
                }
            })
            .catch((error) => {
                console.error(error);
                messageStore.addAxiosError("Failed to fetch application", error);
            });
    };


    useEffect(() => {
        fetchApplication();
    }, []);


    const handleRequestRefresh = () => {
        fetchApplication();
    };



    function amountFormat(num) {
        const formattedNumber = Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        return `$${formattedNumber}`;
    }

    return (
        <div className="w-full min-h-screen" style={{
            padding: '20px',
            backgroundColor: 'transparent',
        }}>
            {application !== null && (
                <div className="w-full h-full" style={{
                    background: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(8px)',
                    borderRadius: '16px',
                    minHeight: 'calc(100vh - 40px)', // 40px accounts for the padding
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                    transition: 'all 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div className="p-6">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center gap-4">
                                <Text weight="bold" size={700}>
                                    {`${application.primaryContact.firstName} ${application.primaryContact.lastName}`}
                                </Text>
                                <div className="flex items-center text-sm text-gray-600">
                                    <Briefcase24Regular className="w-5 h-5" />
                                    <Text className="ml-2">Business Loan</Text>
                                </div>
                                <div className="flex items-center text-sm text-gray-600">
                                    <Money24Regular className="w-5 h-5" />
                                    <Text className="ml-2">{amountFormat(application.loanConsiderationLow)} &ndash; {amountFormat(application.loanConsiderationHigh)}</Text>
                                </div>
                                <div className="flex items-center text-sm text-gray-600">
                                    <Calendar24Regular className="w-5 h-5" />
                                    <Text className="ml-2">Updated: {new Date(application.updateWhen).toLocaleString()}</Text>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-200">
                            <TabList
                                selectedValue={selectedValue}
                                onTabSelect={onTabSelect}
                                className="gap-8"
                            >
                                <Tab
                                    id="Dashboard"
                                    icon={<Dashboard className="w-5 h-5" />}
                                    value="dashboard"
                                    className="px-1 py-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 data-[selected=true]:text-blue-600 data-[selected=true]:border-blue-600"
                                >
                                    Dashboard
                                </Tab>
                                <Tab
                                    id="Transactions"
                                    icon={<Transactions className="w-5 h-5" />}
                                    value="transactions"
                                    className="px-1 py-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 data-[selected=true]:text-blue-600 data-[selected=true]:border-blue-600"
                                >
                                    Transactions
                                </Tab>
                                <Tab
                                    id="CashFlow"
                                    icon={<ChartMultipleIcon className="w-5 h-5" />}
                                    value="cashflow"
                                    className="px-1 py-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 data-[selected=true]:text-blue-600 data-[selected=true]:border-blue-600"
                                >
                                    Cash Flow
                                </Tab>
                                <Tab
                                    id="Statements"
                                    icon={<Statements className="w-5 h-5" />}
                                    value="statements"
                                    className="px-1 py-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 data-[selected=true]:text-blue-600 data-[selected=true]:border-blue-600"
                                >
                                    Statements
                                </Tab>
                            </TabList>
                        </div>
                    </div>

                    <div className="flex-1 p-6" style={{
                        width: '100%',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}>
                        {selectedValue === "dashboard" && (
                            <ApplicantDashboardPage application={application} onRequestRefresh={handleRequestRefresh} />
                        )}
                      
                        {selectedValue === "transactions" && (
                            <TransactionsPage application={application} onRequestRefresh={handleRequestRefresh} />
                        )}
                        {selectedValue === "cashflow" && (
                            <CashFlow application={application} onRequestRefresh={handleRequestRefresh} s />
                        )}
                        {selectedValue === "statements" && (
                            <StatementsPage application={application} onRequestRefresh={handleRequestRefresh}  />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};


export default ApplicantPage;