import { gql, useQuery } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { subDays } from "date-fns";
import DateRangeSelectDialog from "../../dialogs/DateRangeSelect";
import useAccountStore from 'stores/accountStore';
import {
    createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader,
    DataGridHeaderCell, DataGridRow, makeStyles, SearchBox,
    Spinner, TableCellLayout, Tag, TagGroup, tokens,
    Toolbar, ToolbarButton, ToolbarGroup
} from "@fluentui/react-components";
import {
    ArrowSyncCircleRegular,
    CalendarSearchRegular,
    SearchFilled,
    ChevronDownFilled,
    ChevronRightFilled
} from "@fluentui/react-icons";
import { DonutChart } from "@fluentui/react-charting";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        padding: '24px',
        margin: '20px',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        gap: '24px'
    },
    accountCell: {
        fontFamily: 'monospace',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
        padding: '12px 16px',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center'
    },
    chart: {
        backgroundColor: 'transparent',
        flex: '1 0 325px',
        overflowY: 'auto',
        borderRadius: '12px',
        padding: '20px',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
        }
    },
    headerSection: {
        flex: '0 0 auto',  // Don't allow header to grow or shrink
    },
    headerBar: {
        backgroundColor: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        flex: '0 1 48px',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px'
    },
    headerButton: {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)'
        }
    },
    searchBox: {
        minWidth: '300px',
        '& input': {
            borderRadius: '8px',
            transition: 'all 0.2s ease',
            '&:focus': {
                borderColor: tokens.colorBrandBackground,
                boxShadow: `0 0 0 2px ${tokens.colorBrandBackground}20`
            }
        }
    },
    contentSection: {
        flex: 1,          // Allow content to take remaining space
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,     // Important for nested flex containers
        gap: '24px'
    },
    dataGrid: {
        flex: 1,
        overflow: 'auto',
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '& [data-row-id]': {
            transition: 'background-color 0.2s ease',
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            '&:hover': {
                backgroundColor: 'rgba(0, 120, 212, 0.04)'
            }
        }
    },
    gridHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(8px)',
        borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
        '& [role="columnheader"]': {
            fontWeight: '600',
            padding: '16px',
            fontSize: '0.875rem',
            color: tokens.colorNeutralForeground1
        }
    },
    chartContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    spinner: {
        position: 'absolute',
        zIndex: '200',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
    },
    tagGroup: {
        padding: '8px 0',
        gap: '8px',
        flexWrap: 'wrap'
    },
    tag: {
        borderRadius: '16px',
        backgroundColor: 'rgba(0, 120, 212, 0.1)',
        color: tokens.colorBrandForeground1,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    chartContainer: {
        display: 'flex',
        gap: '32px',
        padding: '24px',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
        borderRadius: '24px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        position: 'relative',
        height: '500px',  // Fixed height for charts
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #3B82F6 0%, #10B981 100%)',
        }
    },
    chartTitle: {
        fontSize: '1.5rem',
        fontWeight: '600',
        background: 'linear-gradient(90deg, #2563EB 0%, #3B82F6 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '16px',
        textAlign: 'center'
    },
    chartCard: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)'
        }
    },
    gridSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        maxHeight: 'calc(100vh - 650px)', // Account for charts and header
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden'
    },
    toolbarGroup: {
        gap: '8px',
        alignItems: 'center'
    },
    checkboxButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        padding: '6px 12px',
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)'
        }
    },
    dateRangeButton: {
        backgroundColor: 'rgba(0, 120, 212, 0.08)',
        borderRadius: '8px',
        padding: '6px 12px',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    chartArea: {
        flex: 1,
        position: 'relative',
        minHeight: '0'  // Allow chart to fit in container
    },
    chartStats: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '16px',
        padding: '12px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
    },
        statItem: {
            textAlign: 'center'
        },
        statLabel: {
            fontSize: '0.875rem',
            color: tokens.colorNeutralForeground2,
            marginBottom: '4px'
        },
        statValue: {
            fontSize: '1.25rem',
            fontWeight: '600',
            color: tokens.colorNeutralForeground1
        },
    legend: {
        marginTop: '16px',
        padding: '12px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        maxHeight: '120px',  // Reduced height for legend
        overflowY: 'auto'
    },
        legendItem: {
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            borderRadius: '8px',
            transition: 'background-color 0.2s ease',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }
        },
        legendColor: {
            width: '12px',
            height: '12px',
            borderRadius: '3px',
            marginRight: '8px'
        },
        legendText: {
            fontSize: '0.875rem',
            color: tokens.colorNeutralForeground1
        },
        legendValue: {
            marginLeft: 'auto',
            fontWeight: '500',
            color: tokens.colorNeutralForeground2
    },
  
    gridHeader: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '& [role="columnheader"]': {
            fontWeight: '600',
            color: tokens.colorNeutralForeground1,
            padding: '12px 16px',
            fontSize: '0.875rem'
        }
    },
    gridCell: {
        padding: '12px 16px',
        fontSize: '0.875rem',
        color: tokens.colorNeutralForeground1,
        '&[data-amount-positive="true"]': {
            color: tokens.colorPaletteGreenForeground1,
            fontWeight: '500'
        },
        '&[data-amount-negative="true"]': {
            color: tokens.colorPaletteRedForeground1,
            fontWeight: '500'
        }
    },
    gridRow: {
        fontSize: '0.9rem',
        height: '56px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
        },
        '&:not(:last-child)': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
        }
    },
    categoryCell: {
        padding: '12px 16px',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'inline-flex',
            padding: '4px 12px',
            borderRadius: '8px',
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            border: '1px solid rgba(0, 120, 212, 0.2)',
            color: tokens.colorBrandForeground1,
            fontWeight: '500',
            backdropFilter: 'blur(4px)'
        }
    },
    sourceCell: {
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
        padding: '12px 16px',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center'
    },
    accountCell: {
        fontFamily: 'monospace',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
        padding: '12px 16px',
        fontSize: '0.9rem'
    },
    typeCell: {
        padding: '12px 16px',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'inline-flex',
            padding: '4px 12px',
            borderRadius: '16px',
            fontWeight: '500',
            backdropFilter: 'blur(4px)',
            '&[data-type="Deposit"]': {
                backgroundColor: 'rgba(16, 185, 129, 0.1)',
                color: '#059669',
                border: '1px solid rgba(16, 185, 129, 0.2)'
            },
            '&[data-type="Expense"]': {
                backgroundColor: 'rgba(239, 68, 68, 0.1)',
                color: '#DC2626',
                border: '1px solid rgba(239, 68, 68, 0.2)'
            }
        }
    },
    amountCell: {
        fontFamily: 'monospace',
        fontWeight: '600',
        padding: '12px 16px',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        '&[data-positive="true"]': {
            color: '#059669'
        },
        '&[data-negative="true"]': {
            color: '#DC2626'
        }
    },
    contentWrapper: {
        background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
        borderRadius: '16px',
        padding: '1px', // Small padding to show the gradient border
        margin: '24px 0'
    },
    content: {
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '16px',
        overflow: 'hidden'
    },
    categoryRow: {
        backgroundColor: 'rgba(0, 120, 212, 0.05)',
        fontWeight: '600',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)'
        }
    },
    transactionRow: {
        backgroundColor: 'transparent',
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.02)'
        }
    },
    expandIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        marginRight: '8px',
        color: tokens.colorBrandForeground1,
        cursor: 'pointer',
        borderRadius: '4px',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.1)'
        }
    },
    indent: {
        marginLeft: '32px'
    },
    cellContent: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        minHeight: '48px'
    }
});

function reduce(r, t) {
    const category = t.category || '(No Category)';
    r[category] = r[category] || {
        ...t,
        transactions: [],
        amount: 0,
        category,
        isExpanded: false,
        transactionId: `category-${category}`,
        type: t.amount > 0 ? 'Deposit' : 'Expense'
    };

    r[category].amount = Math.round((r[category].amount + t.amount) * 100) / 100;

    // Add unique identifier to transaction
    const transactionWithId = {
        ...t,
        transactionId: t.transactionId || `transaction-${category}-${r[category].transactions.length}-${t.amount}`
    };
    r[category].transactions.push(transactionWithId);

    return r;
}

const getColumns = (classes, expandedCategories, onToggleExpand) => [
    createTableColumn({
        columnId: "category",
        compare: (a, b) => (a.category || '').localeCompare(b.category || ''),
        renderHeaderCell: () => "Category",
        renderCell: (item) => {
            const isCategory = item.transactions && Array.isArray(item.transactions);
            const isExpanded = isCategory && expandedCategories.includes(item.category);

            return (
                <div className={classes.cellContent}>
                    {isCategory && (
                        <span
                            className={classes.expandIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggleExpand(item.category);
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {isExpanded ? <ChevronDownFilled /> : <ChevronRightFilled />}
                        </span>
                    )}
                    <span className={!isCategory ? classes.indent : undefined}>
                        {item.category || item.description || 'Uncategorized'}
                    </span>
                </div>
            );
        }
    }),
    createTableColumn({
        columnId: "source",
        compare: (a, b) => (a.source || '').localeCompare(b.source || ''),
        renderHeaderCell: () => "Source",
        renderCell: (item) => (
            <TableCellLayout className={classes.sourceCell}>
                {item.source || ''}
            </TableCellLayout>
        )
    }),
    createTableColumn({
        columnId: "type",
        compare: (a, b) => (a.type || '').localeCompare(b.type || ''),
        renderHeaderCell: () => "Type",
        renderCell: (item) => (
            <TableCellLayout className={classes.typeCell}>
                <div data-type={item.type}>
                    {item.type || ''}
                </div>
            </TableCellLayout>
        )
    }),

    createTableColumn({
        columnId: "amount",
        compare: (a, b) => (a.amount || 0) - (b.amount || 0),
        renderHeaderCell: () => "Amount",
        renderCell: (item) => {
            const amount = item.amount || 0;
            const isPositive = amount > 0;
            return (
                <TableCellLayout
                    className={classes.amountCell}
                    data-positive={isPositive}
                    data-negative={!isPositive}
                >
                    {amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </TableCellLayout>
            );
        }
    })
];
const GridSection = ({ items, columns, classes }) => {
    return (
        <div className={classes.gridSection}>
            <DataGrid
                className={classes.dataGrid}
                items={items}
                columns={columns}
                sortable
                getRowId={(item) => item.transactionId || `category-${item.category}`}
            >
                <DataGridHeader className={classes.gridHeader}>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => {
                        const isCategory = item.transactions && Array.isArray(item.transactions);
                        return (
                            <DataGridRow
                                key={rowId}
                                className={isCategory ? classes.categoryRow : classes.transactionRow}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        );
                    }}
                </DataGridBody>
            </DataGrid>
        </div>
    );
};

const columnSizingOptions = {
    Description: {
        defaultWidth: 200,
        minWidth: 160,
        idealWidth: 200,
    },
};

 const GET_ACCOUNTTRANSACTIONS = gql`
  query getAccountTransactions(
    $applicationId: String!, 
    $contactId: String!, 
    $startDate: DateTime!, 
    $endDate: DateTime!,
    $accountIds: [String!]  # Add accountIds parameter
  ) {
    transactions(
      applicationId: $applicationId, 
      contactId: $contactId, 
      startDate: $startDate, 
      endDate: $endDate,
      accountIds: $accountIds  # Include accountIds in transactions query
    ) {
      accountId
      accountNumberDisplay
      accountName
      transactionId
      category
      payeeName
      description
      type
      transactionDate
      amount
      checkNumber
      source
    }
  }
`;



function useChart(data) {
    const classes = useStyles();
    const grouped = useMemo(() => Object.values(data.reduce(reduce, Object.create(null))), [data])
    const chart = useMemo(() => grouped
        .map(t => ({
            legend: t.category + ' - ' + t.amount.toLocaleString("en-us", { style: "currency", currency: "USD" }),
            data: Math.abs(t.amount),
        }))
        .sort((a, b) => a.legend.localeCompare(b.legend))
        , [grouped])
    const total = useMemo(() => grouped
        .map(t => t.amount)
        .reduce((a, b) => Math.round((a + b) * 100) / 100, 0), [chart])
    return { total, chart, grouped }
}

// Enhance the Chart component
const EnhancedChartWrapper = ({ chartData, chartTitle, total, isExpense }) => {
    const classes = useStyles();
    const gradientColors = isExpense
        ? ['#EF4444', '#F87171']
        : ['#10B981', '#34D399'];

    const stats = [
        { label: 'Total Amount', value: total },
        { label: 'Categories', value: chartData.length },
        {
            label: 'Average', value: (parseFloat(total.replace(/[^0-9.-]+/g, '')) / chartData.length)
                .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        }
    ];

    return (
        <div className={classes.chartCard}>
            <h3 className={classes.chartTitle}>{chartTitle}</h3>

            <div className={classes.chartContent}>
                <div className={classes.chartStats}>
                    {stats.map((stat, index) => (
                        <div key={index} className={classes.statItem}>
                            <div className={classes.statLabel}>{stat.label}</div>
                            <div className={classes.statValue}>{stat.value}</div>
                        </div>
                    ))}
                </div>

                <div className={classes.chartArea}>
                    <DonutChart
                        culture={window.navigator.language}
                        data={{
                            chartTitle,
                            chartData,
                        }}
                        innerRadius={65}
                        hideLegend={true}
                        valueInsideDonut={total}
                        colors={gradientColors}
                        enableGradient={true}
                        roundCorners={true}
                        styles={{
                            root: {
                                height: '100%'
                            }
                        }}
                    />
                </div>

                <div className={classes.legend}>
                    {chartData.map((item, index) => (
                        <div key={index} className={classes.legendItem}>
                            <span
                                className={classes.legendColor}
                                style={{ backgroundColor: gradientColors[index % gradientColors.length] }}
                            />
                            <span className={classes.legendText}>
                                {item.legend.split(' - ')[0]}
                            </span>
                            <span className={classes.legendValue}>
                                {item.legend.split(' - ')[1]}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default function CashFlow({ application, onRequestRefresh }) {
    const classes = useStyles();
    const { selectedAccounts } = useAccountStore();
    const [isBusy, setIsBusy] = useState(true);
    const [visibleTags, setVisibleTags] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
    const [startDate, setStartDate] = useState(subDays(new Date(), 90));
    const [endDate, setEndDate] = useState(new Date());
    const [showDeposits, setShowDeposits] = useState(true);
    const [showExpenses, setShowExpenses] = useState(true);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [transactions, setTransactions] = useState([]);
  
    const { data, refetch } = useQuery(GET_ACCOUNTTRANSACTIONS, {
        variables: { applicationId: application.id, contactId: application.primaryContact.contactId, startDate, endDate, accountIds: selectedAccounts },
        onCompleted: data => {
            setTransactions(data.transactions);
            setIsBusy(false);
        },
    });

    const onToggleExpand = useCallback((category) => {
        setExpandedCategories(prev =>
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    }, []);


    const applySearch = useCallback((tags) => {
        let filteredTransactions = data.transactions;
        tags.forEach((tag) => {
            console.log('filtering for ' + tag.text);
            filteredTransactions = filteredTransactions.filter(item =>
                item.description.toLowerCase().includes(tag.text.toLowerCase()));
        });
        setTransactions(filteredTransactions);
    }, [data])

    const handleRefresh = useCallback(async () => {
        setIsBusy(true);
        await refetch();
        applySearch(visibleTags);
        setIsBusy(false);
    }, [applySearch, refetch, visibleTags])

    const handleSearchButton = useCallback(() => {
        if (!searchText) {
            return;
        }
        let updatedTags = [...visibleTags, { value: visibleTags.length + 1, text: searchText }];
        setVisibleTags(updatedTags);
        applySearch(updatedTags);
        setSearchText('')
    }, [applySearch, searchText, visibleTags])

    const handleDateRangeSelect = useCallback((startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setShowDateRangeDialog(false);
    }, [])

    const removeItem = useCallback((event, { value }) => {
        let updatedTags = [...visibleTags].filter((tag) => tag.value !== value);
        setVisibleTags(updatedTags);
        applySearch(updatedTags);
    }, [applySearch, visibleTags]);

    const columns = useMemo(() => getColumns(classes, expandedCategories, onToggleExpand),
        [classes, expandedCategories, onToggleExpand]);

    // Filter and process transactions
    const deposits = useMemo(() =>
        transactions.filter(t => t.amount > 0)
            .map(t => ({
                ...t,
                type: 'Deposit',
                transactionId: `deposit-${t.transactionId || Math.random().toString()}`
            })),
        [transactions]
    );

    const expenses = useMemo(() =>
        transactions.filter(t => t.amount <= 0)
            .map(t => ({
                ...t,
                type: 'Expense',
                transactionId: `expense-${t.transactionId || Math.random().toString()}`
            })),
        [transactions]
    );

    // Process data for charts
    const { grouped: depositsGrouped, chart: depositsChart, total: depositsTotal } = useChart(deposits);
    const { grouped: expensesGrouped, chart: expensesChart, total: expensesTotal } = useChart(expenses);

    // Create table items with proper IDs
    const tableItems = useMemo(() => {
        const items = [];
        const categories = [
            ...(showDeposits ? depositsGrouped : []),
            ...(showExpenses ? expensesGrouped : [])
        ];

        categories.forEach(category => {
            // Add category with guaranteed unique ID
            const categoryId = `category-${category.category}-${category.type}`;
            items.push({
                ...category,
                transactionId: categoryId
            });

            // Add transactions if category is expanded
            if (expandedCategories.includes(category.category)) {
                const categoryTransactions = category.transactions.map((transaction, index) => ({
                    ...transaction,
                    transactionId: `${categoryId}-transaction-${index}`
                }));
                items.push(...categoryTransactions);
            }
        });

        return items;
    }, [depositsGrouped, expensesGrouped, showDeposits, showExpenses, expandedCategories]);

    

    // Main layout remains mostly the same
    return (
        <div className={classes.root}>
            <div className={classes.headerSection}>
                <Toolbar className={classes.headerBar}>
                    <ToolbarGroup className={classes.toolbarGroup}>
                        <SearchBox
                            placeholder="Search transactions..."
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            className={classes.searchBox}
                        />
                        <ToolbarButton
                            icon={<SearchFilled />}
                            onClick={handleSearchButton}
                            className={classes.headerButton}
                        >
                            Search
                        </ToolbarButton>
                    </ToolbarGroup>

                    <ToolbarGroup className={classes.toolbarGroup}>
                        <ToolbarButton
                            icon={<CalendarSearchRegular />}
                            onClick={() => setShowDateRangeDialog(true)}
                            className={classes.dateRangeButton}
                        >
                            {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                        </ToolbarButton>
                        <ToolbarButton
                            icon={<ArrowSyncCircleRegular />}
                            onClick={handleRefresh}
                            className={classes.headerButton}
                        >
                            Refresh
                        </ToolbarButton>
                    </ToolbarGroup>
                </Toolbar>

                {visibleTags.length > 0 && (
                    <TagGroup
                        onDismiss={removeItem}
                        aria-label="Active filters"
                        className={classes.tagGroup}
                    >
                        {visibleTags.map((tag, index) => (
                            <Tag key={index} value={tag.value} className={classes.tag}>
                                {tag.text}
                            </Tag>
                        ))}
                    </TagGroup>
                )}
            </div>

            <div className={classes.contentSection}>
                {/* Charts Section */}
                <div className={classes.chartContainer}>
                    <EnhancedChartWrapper
                        chartData={depositsChart}
                        chartTitle={`${deposits.length.toLocaleString()} Deposits`}
                        total={depositsTotal.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                        isExpense={false}
                    />
                    <EnhancedChartWrapper
                        chartData={expensesChart}
                        chartTitle={`${expenses.length.toLocaleString()} Expenses`}
                        total={expensesTotal.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                        isExpense={true}
                    />
                </div>

                {/* Grid Section */}
                <div className={classes.gridSection}>
                    <DataGrid
                        className={classes.dataGrid}
                        items={tableItems}
                        columns={columns}
                        sortable
                        getRowId={(item) => item.transactionId}
                    >
                        <DataGridHeader className={classes.gridHeader}>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody>
                            {({ item, rowId }) => {
                                const isCategory = item.transactions && Array.isArray(item.transactions);
                                return (
                                    <DataGridRow
                                        key={rowId}
                                        className={isCategory ? classes.categoryRow : classes.transactionRow}
                                    >
                                        {({ renderCell }) => (
                                            <DataGridCell>
                                                {renderCell(item)}
                                            </DataGridCell>
                                        )}
                                    </DataGridRow>
                                );
                            }}
                        </DataGridBody>
                    </DataGrid>
                </div>
            </div>

            <DateRangeSelectDialog
                startDate={startDate}
                endDate={endDate}
                showDialog={showDateRangeDialog}
                onClose={() => setShowDateRangeDialog(false)}
                onSelect={handleDateRangeSelect}
            />
        </div>
    );
}