import React, {useState} from 'react';

import FinicityNav from './Nav';
import FinicityHomePage from './Home';
import FinicityCustomersPage from './Customers';


function getPages() {
    return ["Main", "Customers"];
}

function getPageContent(pageIndex) {
    console.log('get page');
    console.log(pageIndex);
    switch(pageIndex) {
        case "Main":
            return <FinicityHomePage />;
        case "Customers":
            return <FinicityCustomersPage />;
    }
}

const FinicityMainPage = () => {
    const [currentPage, setCurrentPage] = useState("Main");

    return (
        <div className="twoColumnContainer">
            <nav className="nav">
                <FinicityNav currentPage={currentPage} setCurrentPage={(page) => setCurrentPage(page)} />
            </nav>
            <div className="body">
                {getPageContent(currentPage)}
            </div>
        </div>
    )
};

export default FinicityMainPage;