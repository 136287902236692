import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { TenantApiClient } from 'providers/TenantApiClient';
import {
    Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell,
    DataGridRow, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, mergeClasses, Tab,
    TableCellLayout, TabList, tokens, Card, Caption1, Avatar, Tooltip, Spinner, Dropdown, Option
} from "@fluentui/react-components";
import {
    MoreVerticalRegular, EditRegular, DeleteRegular, PeopleCheckmarkRegular,
    SendRegular, SendFilled, ArrowSyncRegular
} from "@fluentui/react-icons";
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import useMessageStore from 'stores/messageStore';
import useTenantStore from "stores/tenantStore";
import ShortcutCard from 'components/Shortcut';
import CreateApplicationDialog from '../dialogs/CreateApplication';
import AddMemberDialog from '../dialogs/AddAgent';
import AssignApplicationDialog from '../dialogs/AssignApplication';
import DeleteApplicationDialog from '../dialogs/DeleteApplication';
import RequestFollowUpDialog from '../dialogs/RequestFollowUp';
import finicityLogo from '../assets/images/logos/finicity-logo.png';
import plaidLogo from '../assets/images/logos/plaid-logo.png';
import backgroundImage from '../assets/images/backgrounds/crm-dark.png';
import backgroundImage2 from '../assets/images/backgrounds/crm-light.png';
import useUserStore from 'stores/userStore';



Chart.register(ArcElement, ChartTooltip, Legend);

const STATUS_OPTIONS = [
    { value: 'Created', label: 'Created' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Started', label: 'Started' },
    { value: 'WaitingForApplicant', label: 'Waiting for Applicant' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Ready', label: 'Ready' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Canceled', label: 'Canceled' }
];

const useStyles = makeStyles({
    root: {
        padding: '32px',
        backgroundImage: `url(${backgroundImage2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
    },
    statsCard: {
        width: '33%',
        padding: '28px 24px',
        maxHeight: '400px',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        color: '#1e1e2f',
        borderRadius: '16px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
        }
    },
    statsContainer: {
        display: 'flex',
        gap: '24px',
        margin: '0 0 32px 0',
        textAlign: 'center'
    },
    cardTitle: {
        fontSize: '1.25rem',
        fontWeight: '600',
        color: '#2D3748',
        marginBottom: '16px'
    },
    cardValue: {
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#1a365d',
        marginBottom: '8px'
    },
    shortcutCardWrapper: {
        position: 'absolute',
        bottom: '24px',
        right: '24px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
            '& > *': {
                backgroundColor: 'rgba(66, 153, 225, 0.08)',
                borderColor: '#4299E1'
            }
        }
    },

    mainGrid: {
        backgroundColor: '#ffffff',
        borderRadius: '16px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden'
    },
    tabList: {
        padding: '16px 24px 0',
        borderBottom: '1px solid #E2E8F0',
        backgroundColor: '#F7FAFC'
    },
    gridHeader: {
        backgroundColor: '#F7FAFC',
        borderBottom: '1px solid #E2E8F0'
    },
    row: {
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(66, 153, 225, 0.05)'
        }
    },
    menuButton: {
        '&:hover': {
            backgroundColor: 'rgba(66, 153, 225, 0.08)'
        }
    },
    statusDropdown: {
        minWidth: '220px',
        marginLeft: '12px'
    },
    pieChart: {
        maxWidth: '300px',
        margin: '20px auto'
    },
    icon: {
        color: '#4299E1',
        fontSize: '24px'
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    avatar: {
        width: '32px',
        height: '32px',
        borderRadius: '8px'
    },
    caption: {
        color: '#718096',
        fontSize: '0.875rem'
    },
    statusMenuItem: {
        width: '100%',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)'
        }
    },
    statusDropdown: {
        minWidth: '200px',
        marginLeft: '8px',
        '& button': {
            width: '100%',
            justifyContent: 'space-between',
            padding: '6px 12px',
            backgroundColor: '#ffffff',
            border: '1px solid #e2e8f0',
            borderRadius: '6px',
            '&:hover': {
                backgroundColor: 'rgba(0, 120, 212, 0.05)',
                borderColor: '#4299E1'
            }
        }
    },
    statusOption: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 12px',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.05)'
        }
    },
    statusBadge: {
        padding: '2px 8px',
        borderRadius: '12px',
        fontSize: '0.875rem',
        fontWeight: '500',
        textAlign: 'center',
        width: 'fit-content',
        whiteSpace: 'nowrap'
    }
});

const getStatusColor = (status) => {
    const statusColors = {
        Created: { bg: '#E6FFFA', text: '#234E52' },
        Pending: { bg: '#EBF4FF', text: '#2C5282' },
        Started: { bg: '#E9F5FE', text: '#2B6BA1' },
        WaitingForApplicant: { bg: '#FEF3C7', text: '#92400E' },
        Processing: { bg: '#E9ECEF', text: '#495057' },
        Ready: { bg: '#F0FFF4', text: '#22543D' },
        Approved: { bg: '#C6F6D5', text: '#276749' },
        Rejected: { bg: '#FED7D7', text: '#9B2C2C' },
        Declined: { bg: '#FED7D7', text: '#9B2C2C' },
        Canceled: { bg: '#E2E8F0', text: '#4A5568' }
    };
    return statusColors[status] || { bg: '#EDF2F7', text: '#4A5568' };
};

const HomePage = () => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const tenantStore = useTenantStore();
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState(null);
    const [isStatusUpdating, setIsStatusUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [tab, setTab] = useState('');
    const [dialogState, setDialogState] = useState({
        create: false,
        addMember: false,
        assign: false,
        delete: false,
        resend: false,
        selectedApp: null,
        selectedContact: null
    });

    const fetchApplications = useCallback(async () => {
        try {
            // Get current user's email
            const userEmailResponse = await TenantApiClient.get('/api/user');


            // Safely extract the email
            const userEmail = userEmailResponse?.email?.toLowerCase();
            if (!userEmail) {
                console.error("User email not found or invalid");
                return;
            }

            // Ensure tenantUsers array exists
            const tenantUsers = tenantStore?.tenantUsers;
            if (!Array.isArray(tenantUsers) || tenantUsers.length === 0) {
                console.error("tenantUsers array is missing or empty");
                return;
            }

            // Find the current user in the tenantUsers array
            const currentUser = tenantUsers.find(user =>
                user.email?.toLowerCase() === userEmail
            );
            console.log(tenantUsers)
            if (!currentUser) {
                console.error("User not found in tenantUsers array");
                return;
            }

            // Debugging: Log the found user
            console.log("Current User:", currentUser);

            // Check if the user has the Agent role
            const isAgent = currentUser.roles?.includes('Agent');

            // Call the appropriate endpoint
            const endpoint = isAgent ? '/api/applications' : '/api/applications/all';
            const data = await TenantApiClient.get(endpoint);

            // Set applications data
            setApplications(data);
        } catch (error) {
            // Log errors and display a friendly message
            console.error("Error fetching applications:", error);
            messageStore.addAxiosError("Failed to return Applicants", error);
        } finally {
            // Stop the loading indicator
            setIsLoading(false);
        }
    }, [messageStore, tenantStore]);


    useEffect(() => {
        const getUserEmail = async () => {
            try {
                const response = await TenantApiClient.get('/api/user');
                setUserEmail(response);
            } catch (error) {
                messageStore.addAxiosError("Failed to get user email", error);
            }
        };

        getUserEmail();
    }, []); // Run once on mount

    useEffect(() => {
        if (userEmail) {
            fetchApplications();
        }
    }, [userEmail, fetchApplications]);



    const handleStatusUpdate = useCallback(async (application, newStatus) => {


        setIsStatusUpdating(true);

        const updateRequest = {
            name: application.name,
            description: application.description,
            loanConsiderationLow: application.loanConsiderationLow,
            loanConsiderationHigh: application.loanConsiderationHigh,
            status: newStatus
        };
        try {
            await TenantApiClient.put(`/api/applications/${application.id}`, updateRequest);
            
            messageStore.addMessage(`Status updated to ${newStatus} successfully!`);
            fetchApplications();
        } catch (error) {
            messageStore.addAxiosError("Failed to update status", error);
        } finally {
            setIsStatusUpdating(false);
        }
    }, [fetchApplications, messageStore]);

    

    useEffect(() => {
        fetchApplications();

    }, [fetchApplications]);

    const filteredApplications = useMemo(() =>
        tab ? applications.filter(a => a.status === tab) : applications,
        [applications, tab]
    );


    const handleAction = useCallback((action, application, e) => {
        e?.stopPropagation();

        switch (action) {
            case 'edit':
                navigate(`/applicant/${application.id}`);
                break;
            case 'delete':
            case 'assign':
            case 'resend':
                setDialogState(prev => ({
                    ...prev,
                    [action]: true,
                    selectedApp: application,
                    selectedContact: action === 'resend' ? application.contacts?.[0] : null
                }));
                break;
            default:
                break;
        }
    }, [navigate]);

    const handleDialogClose = useCallback((dialogType, shouldRefresh = false) => {
        setDialogState(prev => ({
            ...prev,
            [dialogType]: false,
            selectedApp: null,
            selectedContact: null
        }));

        if (shouldRefresh) {
            fetchApplications();
        }
    }, [fetchApplications]);

    const columns = useMemo(() => [
        createTableColumn({
            columnId: 'name',
            renderHeaderCell: () => 'Name',
            renderCell: (application) => (
                <TableCellLayout>
                    {application.primaryContact.firstName} {application.primaryContact.lastName}
                    
                </TableCellLayout>
            ),
        }),
       
         createTableColumn({
             columnId: 'LoanAmount',
             renderHeaderCell: () => 'Loan Amount',
             renderCell: (application) => (
                 <TableCellLayout>
                      ${application.loanConsiderationLow}-${application.loanConsiderationHigh}
                     </TableCellLayout>
             ),
         }),
        createTableColumn({
            columnId: 'status',
            renderHeaderCell: () => 'Status',
            renderCell: (application) => (
                <TableCellLayout>
                    <div
                        className={classes.statusBadge}
                        style={{
                            backgroundColor: getStatusColor(application.status).bg,
                            color: getStatusColor(application.status).text
                        }}
                    >
                        {application.status}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'lending-agent',
            renderHeaderCell: () => 'Lending Agent',
            renderCell: (application) => <TableCellLayout>{application.createdWho}</TableCellLayout>,
        }),
        createTableColumn({
            columnId: 'last-updated',
            renderHeaderCell: () => 'Last Updated',
            renderCell: (application) => (
                <TableCellLayout>
                    {new Date(application.updateWhen).toLocaleString()}
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'icons',
            renderHeaderCell: () => null,
            renderCell: (application) => (
                <TableCellLayout>
                    <div className={mergeClasses(classes.flex, classes.footer)}>
                        {application.primaryContact.plaidLink && (
                            <Tooltip content="Plaid Linked" relationship="label">
                                <Avatar name="Plaid" className={classes.flex} image={{ src: plaidLogo }} />
                            </Tooltip>
                        )}
                        {application.primaryContact.finicityLink && (
                            <Tooltip content="Finicity Linked" relationship="label">
                                <Avatar name="Finicity" className={classes.flex} image={{ src: finicityLogo }} />
                            </Tooltip>
                        )}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'menu',
            renderHeaderCell: () => null,
            renderCell: (item) => (
                <TableCellLayout className={classes.colRight}>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Button
                                icon={<MoreVerticalRegular />}
                                onClick={e => e.stopPropagation()}
                                disabled={isStatusUpdating}
                            />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem icon={<EditRegular />} onClick={e => handleAction('edit', item, e)}>
                                    Edit
                                </MenuItem>
                                <MenuItem icon={<DeleteRegular />} onClick={e => handleAction('delete', item, e)}>
                                    Delete
                                </MenuItem>
                                <MenuItem icon={<PeopleCheckmarkRegular />} onClick={e => handleAction('assign', item, e)}>
                                    Assign
                                </MenuItem>
                                <MenuItem icon={<SendRegular />} onClick={e => handleAction('resend', item, e)}>
                                    Resend Application
                                </MenuItem>
                                <MenuList>
                                    <MenuItem
                                        icon={<ArrowSyncRegular />}
                                        disabled={isStatusUpdating}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <Dropdown
                                                className={classes.statusDropdown}
                                                value={item.status}
                                                onOptionSelect={(_, data) => {
                                                    if (data.optionValue !== item.status) {
                                                        handleStatusUpdate(item, data.optionValue);
                                                    }
                                                }}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {STATUS_OPTIONS.map(status => (
                                                    <Option
                                                        key={status.value}
                                                        value={status.value}
                                                        className={classes.statusOption}
                                                    >
                                                        <div
                                                            className={classes.statusBadge}
                                                            style={{
                                                                backgroundColor: getStatusColor(status.value).bg,
                                                                color: getStatusColor(status.value).text
                                                            }}
                                                        >
                                                            {status.label}
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Dropdown>
                                        </div>
                                    </MenuItem>
                                </MenuList>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </TableCellLayout>
            ),
        }),
       
    ], [classes, handleAction, handleStatusUpdate, isStatusUpdating]);

    const countByStatus = useMemo(() => {
        return applications.reduce((acc, app) => {
            acc[app.status] = (acc[app.status] || 0) + 1;
            return acc;
        }, {});
    }, [applications]);

    const chartData = {
        labels: Object.keys(countByStatus),
        datasets: [{
            data: Object.values(countByStatus),
            backgroundColor: [
                '#4CAF50', '#FFEB3B', '#FFC107', '#FF9800', '#F44336',
                '#2196F3', '#3F51B5', '#9C27B0', '#607D8B', '#795548'
            ]
        }]
    };

    return (
        <>
            <CreateApplicationDialog
                showDialog={dialogState.create}
                onCancelDialog={() => handleDialogClose('create')}
                onComplete={applicationId => {
                    handleDialogClose('create');
                    navigate('/Applicant/' + applicationId);
                }}
            />
            <AddMemberDialog
                tenantId={tenantStore.id}
                showDialog={dialogState.addMember}
                onCancel={() => handleDialogClose('addMember')}
                onComplete={() => handleDialogClose('addMember')}
            />
            <AssignApplicationDialog
                showDialog={dialogState.assign}
                onCancel={() => handleDialogClose('assign')}
                application={dialogState.selectedApp}
                onComplete={() => handleDialogClose('assign', true)}
            />
            <DeleteApplicationDialog
                showDialog={dialogState.delete}
                onCancel={() => handleDialogClose('delete')}
                application={dialogState.selectedApp}
                onComplete={success => handleDialogClose('delete', success)}
            />
            <RequestFollowUpDialog
                showDialog={dialogState.resend}
                onCancel={() => handleDialogClose('resend')}
                application={dialogState.selectedApp}
                contacts={dialogState.selectedContact}
                onRequestSent={() => handleDialogClose('resend')}
            />
            <div className={classes.root}>
            <div className={classes.statsContainer}>
                    <Card className={classes.statsCard}>
                        <h2 className={classes.cardTitle}>Applications</h2>
                        <div className={classes.cardValue}>{applications.length}</div>
                        <div className={classes.shortcutCardWrapper}>
                            <ShortcutCard
                                title="Create New Invitation"
                                onClick={() => setDialogState(prev => ({ ...prev, create: true }))}
                                icon={<SendFilled className={classes.icon} />}
                            />
                    </div>
                    </Card>
                
                    <Card className={classes.statsCard}>
                        <h2 className={classes.cardTitle}>Users</h2>
                    
                        <div className={classes.cardValue}>{tenantStore.tenantUsers.length}</div>
                    <div className={classes.shortcutCardWrapper}>
                        <ShortcutCard
                            title="Create New Agent"
                            onClick={() => setDialogState(prev => ({ ...prev, addMember: true }))}
                                icon={<SendFilled className={classes.icon} />}
                        />
                    </div>
                </Card>
                    <Card className={classes.statsCard}>
                        <h2 className={classes.cardTitle}>Status Distribution</h2>
                    
                    <Pie data={chartData} />
                </Card>
            </div>
         
            <Card className={classes.mainGrid}>
                <TabList
                    className={classes.tabList}
                    selectedValue={tab}
                    onTabSelect={(_, data) => setTab(data.value)}
                >
                    <Tab value="">All</Tab>
                    <Tab value="Created">Created</Tab>
                    <Tab value="WaitingForApplicant">Waiting for Applicant</Tab>
                    <Tab value="Ready">Ready</Tab>
                    <Tab value="Approved">Approved</Tab>
                    <Tab value="Rejected">Rejected</Tab>
                </TabList>

                <DataGrid
                    items={filteredApplications}
                    columns={columns}
                    getRowId={item => item.id}
                >
                    <DataGridHeader className={classes.gridHeader}>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody>
                        {({ item, rowId }) => (
                            <DataGridRow
                                key={rowId}
                                onClick={e => handleAction('edit', item, e)}
                                className={classes.row}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
                {isLoading && <Spinner />}
                </Card>
            </div>
        </>
    );
};

export default HomePage;