import {
  Checkbox,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow, TableCellLayout
} from "@fluentui/react-components";
import React, {useCallback, useMemo, useState} from "react";

export default function Notifications() {
  const [items, setItems] = useState([
    {id: 1, event: 'Customer Connected Bank', isChecked: true},
    {id: 2, event: 'Customer Uploaded Bank Statement', isChecked: true},
    {id: 3, event: 'Customer Uploaded Documents', isChecked: true},
    {id: 4, event: 'Account Disconnected From Vixolink', isChecked: true},
  ])
  const toggleItem = useCallback((id) => {
    setItems(items => items.map(item => {
      if (item.id === id) {
        item.isChecked = !item.isChecked
      }
      return item
    }))
  }, [])
  const columns = useMemo(() => [
    createTableColumn({
      columnId: "isChecked",
      renderHeaderCell: () => 'Select',
      renderCell: (item) => {
        return <TableCellLayout>
          <Checkbox checked={item.isChecked} onChange={e => toggleItem(item.id)} />
        </TableCellLayout>
      },
    }),
    createTableColumn({
      columnId: "event",
      renderHeaderCell: () => 'Event',
      renderCell: (item) => {
        return <TableCellLayout>
          {item.event}
        </TableCellLayout>
      },
    }),
  ], [])

  return (
    <>
      <h2>Notifications</h2>
      <DataGrid
        items={items}
        columns={columns}
        sortable
        getRowId={item => item.id}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </>
  )
}