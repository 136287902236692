import React, { useState, useEffect } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
    Field,
    Checkbox,
    Button,
    ProgressBar,
    Text,
    Divider,
} from "@fluentui/react-components";
import {
    SendFilled,
} from "@fluentui/react-icons";

const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

const RequestFollowUpDialog = ({ application, contacts, showDialog, onCancel, onRequestSent }) => {
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactId, setContactId] = useState('');

    useEffect(() => {
        if (showDialog && contacts) {
            setFirstName(contacts.firstName);
            setlastName(contacts.lastName);
            setEmail(contacts.email);
            setContactId(contacts.contactId);
        }
    }, [showDialog, contacts]);

    const handleSendRequest = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        var loanType = "Business";

        const followUpRequest = {
            applicationId: application.id,
            contactId: contactId,
            todoItems: [
                "Finicity",
                "Will update with full list when Known"
            ],
            message: ""
        };

        setIsBusy(true); 
        setProgressMessage("Progress: Requesting Follow Up");
        TenantApiClient.post("/api/applications/followup", followUpRequest)
            .catch((error) => {
                console.error(error);
                messageStore.addAxiosError("Failed to Resend Application", error);
                onCancel();
            })
            .finally(() => {
                setIsBusy(false);
                setProgressMessage("Progress: Follow Up Sent!");
                if (onRequestSent) {
                    messageStore.addMessage("Follow up sent successfully!"); // Show a success message
                    onRequestSent(true); // Pass `true` to indicate success
                }
            });
    };

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel(data.open)}>
            <DialogSurface>
                <form onSubmit={handleSendRequest}>
                    <DialogBody>
                        <DialogTitle>Request Follow Up Actions</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Field label="First Name">
                                <Label>{firstName}</Label>
                            </Field>
                            <Field label="Last Name">
                                <Label>{lastName}</Label>
                            </Field>
                            <Field label="Email Address">
                                <Label>{email}</Label>
                            </Field>
                            <br />
                            <Label>Application Items</Label>
                            <Field hint="Which Application Items are you requesting?">
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Finicity Account Link" />
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Update when Known" />
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Update when Known" />
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Update when Known" />
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Update when Known" />
                                <Checkbox name="accountLink" id="accountLink" type="checkbox" checked="true" label="Update when Known" />
                            </Field>
                            <br />
                            <Divider />
                            <Text>{progressMessage}</Text>
                            {isBusy &&
                                <ProgressBar />
                            }
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button type="submit" icon={<SendFilled />} appearance="primary">
                                Send Request
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};

export default RequestFollowUpDialog;
