import axios from "axios";

export const BaseApiClient = (baseURL) => {
  const instance = axios.create({baseURL, timeout: 60000});

  instance.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return config;
  });

  const responseBody = (response) => response.data;

  return {
    get: (url) => instance.get(url).then(responseBody),
    getBuffer: (url) => instance.get(url, {responseType: 'arrayBuffer'}),
    getFile: (url) => instance.get(url, {responseType: 'blob'}),
    post: (url, body) => instance.post(url, body).then(responseBody),
    postFile: (url, file) => instance.post(url, file, { headers: { 'Content-Type': 'multipart/form-data' }}).then(responseBody),
    put: (url, body) => instance.put(url, body).then(responseBody),
    delete: (url) => instance.delete(url).then(responseBody),
  }
};