import { useEffect, useCallback, useRef } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';

export const useAccountSync = (application, selectedAccounts, onRequestRefresh) => {
 
    const messageStore = useMessageStore();
    const previousAccountsRef = useRef(new Set());
    const isSyncingRef = useRef(false);

    const handleSync = useCallback(async (accountId, source) => {
     
        const endpoints = {
         
            base: source === 'Plaid'
                ? `/api/plaid/Transactions/${application.id}/${application.primaryContact.contactId}/${accountId}`
                : `/api/finicity/Transactions/${application.id}/${application.primaryContact.contactId}/${accountId}`,
            extended: source === 'Plaid'
                ? `/api/plaid/HistoricTransactions/${application.id}/${application.primaryContact.contactId}/${accountId}`
                : `/api/finicity/HistoricTransactions/${application.id}/${application.primaryContact.contactId}/${accountId}`
        };
        console.log("HERE2");
        try {
            if (selectedAccounts.length === 0)
            {
                return false;
            }
            await Promise.all([
                TenantApiClient.post(endpoints.base),
                source === 'Plaid' ? TenantApiClient.post(endpoints.extended) : Promise.resolve()
            ]);
            return true;
        } catch (error) {
     
            messageStore.addAxiosError(`Failed to sync transactions for account`, error);
            return false;
        }
    }, [application, messageStore]);

    const hasSyncedOnce = useRef(false);

    const syncNewAccounts = useCallback(async () => {
        console.log("HERE3");
        if (isSyncingRef.current || !application?.primaryContact || hasSyncedOnce.current) return;

        try {
            isSyncingRef.current = true;

            // Get all current accounts
            const finicityAccounts = application.primaryContact.finicityLink?.accounts || [];
            const plaidAccounts = application.primaryContact.plaidLink?.items?.flatMap(item =>
                item.accounts.map(acc => ({ ...acc, source: 'Plaid' }))
            ) || [];

            const allAccounts = [
                ...finicityAccounts.map(acc => ({ ...acc, source: 'Finicity' })),
                ...plaidAccounts
            ];

            const previousAccounts = previousAccountsRef.current;
            const currentAccountIds = new Set(allAccounts.map(acc => acc.id));

            // Find accounts that need syncing
            const accountsToSync = allAccounts.filter(account =>
                // Don't sync FILE accounts
                !account.id.startsWith('FILE') &&
                (!previousAccounts.has(account.id) || !account.isTransactionSynced) &&
                selectedAccounts.includes(account.id)
            );

  
            if (accountsToSync.length > 0) {

                const syncPromises = accountsToSync.map(account =>
                    handleSync(account.id, account.source)
                );

                await Promise.all(syncPromises);

                if (onRequestRefresh) {
                    onRequestRefresh();
                }
            }

            previousAccountsRef.current = currentAccountIds;

        } finally {
            isSyncingRef.current = false;
        }

        hasSyncedOnce.current = true;
    }, [application, selectedAccounts, handleSync, onRequestRefresh, messageStore]);

    // Run on initial load and when dependencies change
    useEffect(() => {
        if (application?.primaryContact) {
            const finicityAccounts = application.primaryContact.finicityLink?.accounts || [];
            const plaidAccounts = application.primaryContact.plaidLink?.items?.flatMap(item =>
                item.accounts.map(acc => ({ ...acc, source: 'Plaid' }))
            ) || [];
            const allAccounts = [
                ...finicityAccounts.map(acc => ({ ...acc, source: 'Finicity' })),
                ...plaidAccounts
            ];
            previousAccountsRef.current = new Set(allAccounts.map(acc => acc.id));
        }
    }, [application]);

    return { handleSync };
};