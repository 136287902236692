import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const accountStore = create(
    persist(
        (set, get) => ({
            selectedAccounts: [],

            setSelectedAccounts: (accounts) => {
                set({ selectedAccounts: accounts });
            },

            toggleAccountSelection: (accountId) => {
                set((state) => {
                    const currentAccounts = state.selectedAccounts;
                    const isSelected = currentAccounts.includes(accountId);

                    return {
                        selectedAccounts: isSelected
                            ? currentAccounts.filter(id => id !== accountId)
                            : [...currentAccounts, accountId]
                    };
                });
            },

            initializeFromApplication: (application) => {
                if (!application) return;

                // Get accounts from both Finicity and Plaid links if they exist
                const finicityAccounts = application?.primaryContact?.finicityLink?.accountIds || [];
                const plaidAccounts = application?.primaryContact?.plaidLink?.items?.flatMap(
                    item => item.accounts?.map(acc => acc.id)
                ) || [];

                // Combine all account IDs
                const allAccounts = [...new Set([...finicityAccounts, ...plaidAccounts])];

                // Only set if we have accounts and current selection is empty
                if (allAccounts.length > 0 && get().selectedAccounts.length === 0) {
                    set({ selectedAccounts: allAccounts });
                }
            },

            // Add method to clear selection
            clearSelectedAccounts: () => {
                set({ selectedAccounts: [] });
            }
        }),
        {
            name: 'account-storage', // unique name for localStorage key
            getStorage: () => localStorage, // Use localStorage for persistence
            partialize: (state) => ({ selectedAccounts: state.selectedAccounts }), // Only persist selectedAccounts
        }
    )
);

export default accountStore;