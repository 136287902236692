import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, tokens } from "@fluentui/react-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CalendarChatRegular
} from "@fluentui/react-icons";
import { Widget } from 'components/Widget';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Button,
  } from "@fluentui/react-components";

const invitationColumns = [
    { columnKey: "status", label: "Status" },
    { columnKey: "invitationCode", label: "Code" },
    { columnKey: "action", label: "Copy"},
    { columnKey: "link", label: "Link"}
  ];

const useClasses = makeStyles({
    widget: {
        margin: "0px",
        padding: "0px",
        minHeight: "300px",
    },
    table: {
        width: "100%",
    },
    tableHeader: {
    },
    tableBody: {
    },
    tableRow: {
    },
});

const Notifications = ({application}) => {
    const classes = useClasses();

    const getInvitationLink = (invitationCode) => {
        return `${process.env.REACT_APP_APPLICANT_URL}?invitation=${invitationCode}`;
    };
    return (
        <div className="flex flex-col col-span-full sm:col-span-6 sm:row-span-2 xl:col-span-3 shadow-sm rounded-xl">
            <Widget widgetClass={classes.widget} image={<CalendarChatRegular />}
                title="Notifications" caption="Coming Soon!!!">
                <p>Notifications Go Here</p>
            </Widget>
        </div>
    )
};

export default Notifications;