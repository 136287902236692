import { gql } from '@apollo/client';

export const GET_TRANSACTION_TOTALS = gql`
  query GetTransactionTotals(
    $applicationId: String!, 
    $contactId: String!, 
    $accountIds: [String!]
  ) {
    transactionTotals(
      applicationId: $applicationId, 
      contactId: $contactId, 
      accountIds: $accountIds
    ) {
      totalTransactions
      totalSpending
      totalDeposits
      totalPayments
      averageDailyBalance
      daysWithNegativeBalance
      monthlyTotals {
        totalTransactions
        totalSpending
        totalDeposits
        totalPayments
        averageDailyBalance
        daysWithNegativeBalance
      }
      dailyBalances {
        date
        balance
        accountId
      }
    }
  }
`;




export const GET_ACCOUNT_BALANCES = gql`
  query GetAccountBalances($applicationId: String!, $contactId: String!, $accountIds: [String!], $startDate: DateTime!, $endDate: DateTime!) {
    accountBalances(
      applicationId: $applicationId, 
      contactId: $contactId, 
      accountIds: $accountIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      accountId
      source
      balanceHistory {
        date
        balance
        available
      }
      currentBalance {
        current
        available
      }
    }
  }
`;