import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow, TableCellLayout
} from "@fluentui/react-components";
import React, {useMemo, useState} from "react";
import currency from "../../../utils/currency";

export default function ActivityLog() {
  const [items, setItems] = useState([
    {date: '8/27', applicant: 'Test Applicant', id: '123', agent: 'Test Agent', log: 'Test Log', cost: 0.00},
  ])
  const columns = useMemo(() => [
    createTableColumn({
      columnId: "date",
      renderHeaderCell: () => 'Date',
      renderCell: (item) => <TableCellLayout>{item.date}</TableCellLayout>,
    }),
    createTableColumn({
      columnId: "applicant",
      renderHeaderCell: () => 'Applicant Name',
      renderCell: (item) => <TableCellLayout>{item.applicant}</TableCellLayout>,
    }),
    createTableColumn({
      columnId: "id",
      renderHeaderCell: () => 'Vixo Applicant ID',
      renderCell: (item) => <TableCellLayout>{item.id}</TableCellLayout>,
    }),
    createTableColumn({
      columnId: "agent",
      renderHeaderCell: () => 'Lending Agent Name',
      renderCell: (item) => <TableCellLayout>{item.agent}</TableCellLayout>,
    }),
    createTableColumn({
      columnId: "log",
      renderHeaderCell: () => 'Activity Description',
      renderCell: (item) => <TableCellLayout>{item.log}</TableCellLayout>,
    }),
    createTableColumn({
      columnId: "cost",
      renderHeaderCell: () => 'Cost',
      renderCell: (item) => <TableCellLayout>{currency(item.cost)}</TableCellLayout>,
    }),
  ], [])

  return (
    <>
      <h2>Activity Log</h2>
      <DataGrid
        items={items}
        columns={columns}
        sortable
        getRowId={item => item.id}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </>
  )
}