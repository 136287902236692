import React from 'react';
import {
    Hamburger,
    NavCategory,
    NavCategoryItem,
    NavDrawer,
    NavDrawerBody,
    NavDrawerHeader,
    NavDrawerProps,
    NavItem,
    NavSectionHeader,
    NavSubItem,
    NavSubItemGroup,
    AppItem,
  } from "@fluentui/react-nav-preview";
  import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

  import {
    Label,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    useId,
  } from "@fluentui/react-components";

  import {
    Board20Filled,
    Board20Regular,
    Person20Filled,
    Person20Regular,
    bundleIcon,
    People20Filled,
    People20Regular,
    BuildingPeople20Filled,
    BuildingPeople20Regular,
  } from "@fluentui/react-icons";

const People = bundleIcon(People20Filled, People20Regular);
const Person = bundleIcon(Person20Filled, Person20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Building = bundleIcon(BuildingPeople20Filled, BuildingPeople20Regular);

const useClasses = makeStyles({

  drawer: {
      height: "100vh",
  }
});

const BillingNav = ({currentPage, setCurrentPage, tenants}) => {
  const classes = useClasses();
  function onSelect(event, data) {
    setCurrentPage(data.value);
  }

  return (
      <NavDrawer
      className={classes.drawer}
      defaultSelectedValue={currentPage}
      open={true}
      type={"inline"}
      size={"medium"}
      onNavItemSelect={onSelect}
    >
      <NavDrawerHeader>
        <Label>Billing Account Menu</Label>
      </NavDrawerHeader>
      <NavDrawerBody>
        <NavItem icon={<Dashboard />} value="Main">
          Dashboard
        </NavItem>
        <NavCategory value="6">
        <NavCategoryItem icon={<Building />}>
            Tenants
        </NavCategoryItem>
        <NavSubItemGroup>
          {tenants.map((tenant) => (
            <NavSubItem key={tenant.id} icon={<Person />} value={tenant.id}>{tenant.displayName}</NavSubItem>
          ))}
        </NavSubItemGroup>
        </NavCategory>
      </NavDrawerBody>
    </NavDrawer>
  )
};

export default BillingNav;