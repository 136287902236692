import React from 'react';
import { makeStyles, tokens } from "@fluentui/react-components";
import { Toolbar, ToolbarGroup, Button, Label, ToolbarButton, Avatar } from "@fluentui/react-components";
import {
    SettingsRegular,
    AlertRegular,
} from "@fluentui/react-icons";
import useTenantStore from "stores/tenantStore";

const useStyles = makeStyles({
    headerContainer: {
        position: 'fixed',
        top: 0,
        left: '72px',
        right: 0,
        height: '42px',
        backgroundColor: 'white',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        zIndex: 90,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginRight: 'auto',
        padding: '0 4px',
    },
    productName: {
        fontSize: '16px',
        fontWeight: '600',
        color: tokens.colorBrandForeground1,
        letterSpacing: '-0.01em',
    },
    separator: {
        color: tokens.colorNeutralForeground3,
        fontSize: '16px',
        fontWeight: '300',
        margin: '0 2px',
    },
    tenantName: {
        fontSize: '16px',
        fontWeight: '600',
        color: tokens.colorNeutralForeground2,
        display: 'flex',
        alignItems: 'center',
        gap: '6px',

        '::before': {
            content: '""',
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            backgroundColor: tokens.colorNeutralForeground3,
            display: 'inline-block',
        }
    },
    userMenuGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        height: '100%',
        padding: '0 4px',
    },
    headerButton: {
        minWidth: '28px',
        height: '28px',
        padding: '2px',
        backgroundColor: 'transparent',
        color: tokens.colorNeutralForeground2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '4px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2,
            color: tokens.colorNeutralForeground1,
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground3,
        }
    },
    headerButtonIcon: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: '28px',
        height: '28px',
        cursor: 'pointer',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2,
            transform: 'translateY(-1px)',
        },
        ':active': {
            transform: 'translateY(0)',
        }
    }
});
export const HeaderBar = ({ onShowUserProfile, onShowSettings }) => {
    const styles = useStyles();
    const tenantStore = useTenantStore();

    return (
        <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
                <span className={styles.productName}>Vixo Link</span>
                <span className={styles.tenantName}>{tenantStore.displayName}</span>
            </div>
            <div className={styles.userMenuGroup}>
                <button className={styles.headerButton}>
                    <AlertRegular className={styles.headerButtonIcon} />
                </button>
                <button
                    className={styles.headerButton}
                    onClick={onShowSettings}
                >
                    <SettingsRegular className={styles.headerButtonIcon} />
                </button>
                <Avatar
                    size={28}
                    color="brand"
                    className={styles.avatar}
                    onClick={onShowUserProfile}
                />
            </div>
        </div>
    );
};

