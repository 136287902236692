import React, {useState } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
    Field,
    Checkbox,
    Button,
    ProgressBar,
    Text,
    Divider,
  } from "@fluentui/react-components";
import {
    SendFilled,
} from "@fluentui/react-icons";

  const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
  })

const AddContactDialog = ({application, showDialog, onCancel, onComplete}) =>{
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const handleAddApplication = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        var loanType = "Business";
        const newContact = {
            firstName: formProps.firstName,
            lastName: formProps.lastName,
            email: formProps.email,
            mobilePhone: formProps.phone,
        };

        console.log(newContact);

        setIsBusy(true);
        setProgressMessage("Progress: Adding Contact");
        TenantApiClient.post(`/api/applications/addcontact/${application.id}`, newContact)
        .then((data) => {
            setProgressMessage("Progress: Sending Invitation");

            const request = {
                applicationId: application.id,
                contactId: data.contactId,
                TodoItems: ["ContactInfo", "Finicity"]
            };

            TenantApiClient.post('api/applications/invite', request)
            .then((data) => {
                if (onComplete) {
                    onComplete(data.id);
                }
            })
            .catch((error) => {
                console.error(error);
                messageStore.addAxiosError("Failed to Send Invitation", error);
                onCancel();
            });
        })
        .catch((error) => {
            console.error(error);
            messageStore.addAxiosError("Failed to Create Application", error);
            onCancel();
        });
    };

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel(data.open)}>
            <DialogSurface>
            <form onSubmit={handleAddApplication}>
            <DialogBody>
                <DialogTitle>Invite Additional Contact</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Field required placeholder="First Name" label="First Name">
                        <Input required type="text" name="firstName" id={"firstName"} />
                    </Field>
                    <Field required placeholder="Last Name" label="Last Name">
                        <Input required type="text" name="lastName" id={"lastName"} />
                    </Field>
                    <Field required label="Email Address">
                        <Input required type="email" name="email" id={"email"} />
                    </Field>
                    <Field label="Mobile Phone">
                        <Input type="phone" name="phone" id={"phone"} />
                    </Field>
                    <br/>
                    <Label>Application Items</Label>
                    <Field hint="Which Applicaiton Items are you requesting?">
                        <Checkbox name="contactInfo" id="contactInfo" checked="true" disabled label="Contact Info (required)" />
                        <Checkbox name="accountLink" id="accountLink" checked="true" label="Finicity Account Link" />
                    </Field>
                    <br />
                    <Divider/>

                    <Text>{progressMessage}</Text>
                    {isBusy && 
                        <ProgressBar />
                    }
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Cancel</Button>
                    </DialogTrigger>
                    <Button type="submit" icon={<SendFilled />} appearance="primary">
                        Send Invitation
                    </Button>
                </DialogActions>
            </DialogBody>
            </form>
            </DialogSurface>
        </Dialog>
    )
}

export default AddContactDialog;