import React from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    
});

const FinicityHomePage = () => {
    return (
        <p>Finicity page goes here</p>
    )
};

export default FinicityHomePage;