import React, { useState } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Text,
    ProgressBar
} from "@fluentui/react-components";

const DeleteApplicationDialog = ({ application, showDialog, onCancel, onComplete }) => {
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(false);

    const handleDelete = () => {
        if (!application?.id) {
            messageStore.addMessage("Application ID is missing or invalid.");
            return;
        }

        setIsBusy(true);

        const request = {
            Name: application.Name,
            Description: application.Description,
            Status: 'Canceled',
            LoanConsiderationLow: application.LoanConsiderationLow,
            LoanConsiderationHigh: application.LoanConsiderationHigh,
        };


        TenantApiClient.put(`/api/Applications/${application.id}`, request)
            .then(() => {
                messageStore.addMessage("Application deleted successfully!");
                if (onComplete) onComplete(true);
            })
            .catch(error => {
                messageStore.addAxiosError("Failed to delete application", error);
            })
            .finally(() => {
                setIsBusy(false);
            });
    };

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel(data.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Delete Application</DialogTitle>
                    <DialogContent className="space-y-4">
                        <Text>
                            Are you sure you want to delete the application for{' '}
                            {application?.primaryContact?.firstName} {application?.primaryContact?.lastName}?
                        </Text>

                        {isBusy && <ProgressBar />}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance="secondary"
                            onClick={() => onCancel(false)}
                            disabled={isBusy}
                        >
                            Cancel
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={handleDelete}
                            disabled={isBusy}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default DeleteApplicationDialog;