import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import useMessageStore from 'stores/messageStore';
import useAccountStore from 'stores/accountStore';
import { TenantApiClient } from 'providers/TenantApiClient';
import isEqual from 'lodash/isEqual';
import {
    makeStyles,
    Button,
    tokens,
    Table,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableRow,
    Checkbox,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Alert,
} from "@fluentui/react-components";
import {
    MoreVerticalRegular,
    PersonAccounts24Filled,
    CheckmarkCircle24Filled,
    ArrowSyncCircle24Regular,
    DeleteRegular,
    AlertRegular,
    WarningFilled,
} from "@fluentui/react-icons";
import { Widget } from 'components/Widget';
import PropTypes from 'prop-types';
import { useAccountSync } from 'utils/useAccountSync';

const useStyles = makeStyles({
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
        backgroundColor: 'transparent',
        tableLayout: 'fixed',
    },
    headerCell: {
        backgroundColor: tokens.colorNeutralBackground2,
        padding: '16px 12px',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: tokens.colorNeutralStroke1,
        fontWeight: '600',
        textAlign: 'left',
        color: tokens.colorNeutralForeground1,
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '13px',
        letterSpacing: '0.3px',
        textTransform: 'uppercase',
    },
    cell: {
        padding: '12px',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: tokens.colorNeutralStroke1,
        textAlign: 'left',
        backgroundColor: 'transparent',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        height: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        transition: 'background-color 0.2s ease',
    },
    checkbox: {
        margin: '0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    syncButton: {
        minWidth: '85px',
        height: '32px',
        borderRadius: '16px',
        fontWeight: '500',
        fontSize: '13px',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackground2,
            transform: 'translateY(-1px)',
        },
    },
    syncedIcon: {
        color: tokens.colorPaletteGreenForeground1,
        width: '24px',
        height: '24px',
        margin: '0 auto',
        display: 'block',
    },
    tableContainer: {
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: '12px',
        padding: '20px',
        margin: '8px 0',
        overflowX: 'auto',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    },
    spinner: {
        padding: '32px',
        textAlign: 'center',
        color: tokens.colorBrandForeground1,
    },
    notSynced: {
        color: tokens.colorNeutralForeground3,
        fontSize: '13px',
    },
    synced: {
        color: tokens.colorPaletteGreenForeground1,
        fontSize: '13px',
        fontWeight: '500',
    },
    actionButton: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity 0.2s ease',
    },
    row: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
            '& $actionButton': {
                opacity: 1,
            },
        },
    },
    institutionName: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontWeight: '500',
    },
    accountNumber: {
        color: tokens.colorNeutralForeground2,
        fontSize: '13px',
    },
    syncingText: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        fontSize: '13px',
        color: tokens.colorBrandForeground1,
    },
    loadingIcon: {
        animation: 'spin 1s linear infinite',
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    menuTrigger: {
        height: '32px',
        width: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3,
        }
    },
    menuContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        minWidth: '32px',
        height: '32px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        opacity: 0.6, // Changed from 0 to 0.6 for default visibility
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            opacity: 1, // Full opacity on hover
        }
    },
    row: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
            '& $menuButton': {
                opacity: 1, // Full opacity on row hover
            },
        },
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        }
    },
    deleteMenuItem: {
        color: tokens.colorPaletteRedForeground1,
        '&:hover': {
            backgroundColor: tokens.colorPaletteRedBackground1,
        }
    },
    row: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
            '& $menuButton': {
                opacity: 1,
            },
        },
    },
    menuIcon: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    duplicateAccountCell: {
        position: 'relative',
        backgroundColor: tokens.colorStatusWarningBackground,
        '&:hover': {
            backgroundColor: tokens.colorStatusWarningBackgroundHover,
        }
    },
    warningIcon: {
        color: tokens.colorStatusWarningForeground1,
        marginLeft: '8px',
        verticalAlign: 'middle',
    },
    accountNumberText: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    duplicateWarningTooltip: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: tokens.colorNeutralBackground1,
        padding: '4px 8px',
        borderRadius: '4px',
        boxShadow: tokens.shadow4,
        zIndex: 100,
        fontSize: '12px',
        whiteSpace: 'nowrap',
        display: 'none',
        '.duplicateAccountCell:hover &': {
            display: 'block',
        }
    }

});


const GET_ACCOUNTS = gql`
  query GetAccountsAsync($applicationId: String!, $contactId: String!, $accountIds: [String!]) {
    accounts(
      applicationId: $applicationId,
      contactId: $contactId,
      accountIds: $accountIds
    ) {
      source
      accountId
      accountNumberDisplay
      accountName
      institutionId
      institutionName
      institutionLogo
      institutionIcon
      isTransactionSynced
      isHistoricTransactionsEnabled
      isStatementsSynced
      isVisible
    }
  }
`;

const LinkedAccounts = ({ application, onRequestRefresh }) => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const [syncingStates, setSyncingStates] = useState({});
    const hasAttemptedInitialSync = useRef(false);
    const lastSeenDataRef = useRef(null);
    const {
        selectedAccounts,
        setSelectedAccounts,
        toggleAccountSelection,
        initializeFromApplication
    } = useAccountStore();


        const { handleSync } = useAccountSync(application, selectedAccounts, onRequestRefresh);
        const { loading, error, data, refetch } = useQuery(GET_ACCOUNTS, {
            variables: {
                applicationId: application.id,
                contactId: application.primaryContact.contactId,
                accountIds: null
            },
            onCompleted: (data) => {
                if (data?.accounts && selectedAccounts.length === 0) {
                    const initiallyVisibleAccounts = data.accounts
                        .filter(account => account.source !== 'Upload')
                        .map(account => account.accountId);
                    setSelectedAccounts(initiallyVisibleAccounts);
                }
            },
        });



        useEffect(() => {
            const syncUnsyncedAccounts = async () => {
                if (
                    loading ||
                    !data?.accounts ||
                    hasAttemptedInitialSync.current ||
                    isEqual(data, lastSeenDataRef.current)
                ) return;

                lastSeenDataRef.current = data;
                hasAttemptedInitialSync.current = true;

                const unsyncedAccounts = data.accounts.filter(account =>
                    !account.accountId.startsWith('FILE') &&
                    !account.isTransactionSynced
                );

                if (unsyncedAccounts.length === 0) return;
                messageStore.addMessage('Info', `Starting sync for ${unsyncedAccounts.length} accounts`);

                for (const account of unsyncedAccounts) {
                    try {
                        setSyncingStates(prev => ({
                            ...prev,
                            [`${account.accountId}-transactions`]: true
                        }));

                        if (account.source === 'Finicity') {
                            await TenantApiClient.post(
                                `/api/finicity/Transactions/${application.id}/${application.primaryContact.contactId}/${account.accountId}`
                            );
                        } else {
                            await Promise.all([
                                TenantApiClient.post(
                                    `/api/plaid/Transactions/${application.id}/${application.primaryContact.contactId}/${account.accountId}`
                                ),
                                TenantApiClient.post(
                                    `/api/plaid/HistoricTransactions/${application.id}/${application.primaryContact.contactId}/${account.accountId}`
                                )
                            ]);
                        }
                    } catch (error) {
                        messageStore.addAxiosError(`Failed to sync account ${account.accountId}`, error);
                    } finally {
                        setSyncingStates(prev => ({
                            ...prev,
                            [`${account.accountId}-transactions`]: false
                        }));
                    }
                }
                await refetch();
                if (onRequestRefresh) {
                    onRequestRefresh();
                }
            };

            syncUnsyncedAccounts();
        }, [data, loading]);

        useEffect(() => {
            hasAttemptedInitialSync.current = false;
            lastSeenDataRef.current = null;
        }, [application?.id, selectedAccounts.length]);

        useEffect(() => {
            initializeFromApplication(application);
        }, [application]);

        const handleSyncAccount = async (accountId, type) => {
            if (accountId.startsWith('FILE')) {
                console.log('Skipping sync for file-based account:', accountId);
                return;
            }

            const isPlaidAccount = application?.primaryContact?.plaidLink?.items?.some(item =>
                item.accounts?.some(acc => acc.id === accountId)
            ) ?? false;

            setSyncingStates(prev => ({
                ...prev,
                [`${accountId}-${type}`]: true,
                ...(isPlaidAccount && type === 'transactions' ? {
                    [`${accountId}-extended`]: true
                } : {})
            }));

            try {
                if (type === 'account') {
                    await TenantApiClient.get(`/api/finicity/SyncAccount/${accountId}/${application.id}/${application.primaryContact.contactId}`);
                } else {
                    await handleSync(accountId, isPlaidAccount ? 'Plaid' : 'Finicity');
                }
                await refetch();
                if (onRequestRefresh) {
                    onRequestRefresh();
                }
            } catch (error) {
                messageStore.addAxiosError(`Failed to sync ${type}`, error);
            } finally {
                setSyncingStates(prev => ({
                    ...prev,
                    [`${accountId}-${type}`]: false,
                    ...(isPlaidAccount && type === 'transactions' ? {
                        [`${accountId}-extended`]: false
                    } : {})
                }));
            }
        };




    // Add duplicate detection logic
    const findDuplicates = useMemo(() => {
        if (!data?.accounts) return new Set();

        const accountNumbers = new Map();
        const duplicates = new Set();

        data.accounts.forEach(account => {
            const accountNum = account.accountNumberDisplay;
            if (!accountNum) return;

            if (accountNumbers.has(accountNum)) {
                duplicates.add(accountNum);
            } else {
                accountNumbers.set(accountNum, account);
            }
        });

        return duplicates;
    }, [data?.accounts]);

    // Function to check if an account number is a duplicate
    const isDuplicate = (accountNumber) => findDuplicates.has(accountNumber);

    // Modify the account number cell render logic
    const renderAccountNumberCell = (account) => {
        const isDuplicateAccount = isDuplicate(account.accountNumberDisplay);
    }


        const handleDeleteAccount = async (accountId) => {
            try {
                await TenantApiClient.delete(
                    `/api/applications/${application.id}/${application.primaryContact.contactId}/accounts/${accountId}`
                );
                messageStore.addMessage('Success', 'Account successfully deleted');
                await refetch();
                if (onRequestRefresh) {
                    onRequestRefresh();
                }
            } catch (error) {
                messageStore.addAxiosError('Failed to delete account', error);
            }
        };

        const toggleAccountVisibility = async (accountId, checked) => {
            try {
                await TenantApiClient.put(
                    `/api/applications/${application.id}/${application.primaryContact.contactId}/ToggleAccountVisibility/${accountId}`
                );
                toggleAccountSelection(accountId);

                if (onRequestRefresh) {
                    onRequestRefresh();
                }
            } catch (error) {
                messageStore.addAxiosError('Failed to toggle account visibility', error);
            }
        };

        if (error) {
            return (
                <Widget
                    icon={<PersonAccounts24Filled />}
                    title="Linked Accounts"
                >
                    <div className="text-red-500 p-4">Error loading accounts: {error.message}</div>
                </Widget>
            );
        }



        const accounts = data?.accounts?.filter(account => account.source !== 'Upload') || [];

        const columns = [
            { key: 'selector', label: '', width: '48px' },
            { key: 'institution', label: 'Institution', width: '22%' },
            { key: 'accountName', label: 'Account Name', width: '20%' },
            { key: 'accountNumber', label: 'Account No.', width: '15%' },
            { key: 'syncAccount', label: 'Account Sync', width: '120px' },
            { key: 'syncTransactions', label: 'Transactions', width: '120px' },
            { key: 'extendedHistory', label: 'History', width: '120px' },
            { key: 'status', label: 'Status', width: '100px' },
            { key: 'actions', label: '', width: '48px' }
        ];

        const SyncButton = ({ onClick, disabled, syncing }) => (
            <Button
                className={classes.syncButton}
                onClick={onClick}
                disabled={disabled}
            >
                {syncing ? (
                    <span className={classes.syncingText}>
                        <ArrowSyncCircle24Regular className={classes.loadingIcon} />
                        Syncing
                    </span>
                ) : (
                    'Sync'
                )}
            </Button>
        );



        return (
            <Widget
                icon={<PersonAccounts24Filled />}
                title="Linked Accounts"
            >
                <div className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHeader>
                            <TableRow>
                                {columns.map(column => (
                                    <TableHeaderCell
                                        key={column.key}
                                        className={classes.headerCell}
                                        style={{
                                            width: column.width,
                                            minWidth: column.width,
                                            maxWidth: column.width
                                        }}
                                    >
                                        {column.label}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className={classes.cell}>
                                        <div className={classes.spinner}>
                                            <ArrowSyncCircle24Regular className={classes.loadingIcon} />
                                            <div style={{ marginTop: '8px' }}>Loading accounts...</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : accounts.map(account => (
                                <TableRow key={account.accountId} className={classes.row}>
                                    <TableCell className={classes.cell} style={{ width: '48px' }}>
                                        <div className={classes.checkbox}>
                                            <Checkbox
                                                checked={selectedAccounts.includes(account.accountId)}
                                                onChange={(ev, data) => toggleAccountVisibility(account.accountId, data.checked)}
                                                aria-label={`Toggle visibility for ${account.accountName}`}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '22%' }}>
                                        <div className={classes.institutionName}>
                                            {account.institutionName}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '20%' }}>
                                        {account.accountName}
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.cell} ${isDuplicate(account.accountNumberDisplay) ? classes.duplicateAccountCell : ''}`}
                                    >
                                        <div className={classes.accountNumberText}>
                                            {account.accountNumberDisplay}
                                            {isDuplicate(account.accountNumberDisplay) && (
                                                <>
                                                    <WarningFilled className={classes.warningIcon} />
                                                    <div className={classes.duplicateWarningTooltip}>
                                                        Duplicate account number detected
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '120px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {account.isAccountSynced ? (
                                                <CheckmarkCircle24Filled className={classes.syncedIcon} />
                                            ) : (
                                                <SyncButton
                                                    onClick={() => handleSyncAccount(account.accountId, 'account')}
                                                    disabled={syncingStates[`${account.accountId}-account`]}
                                                    syncing={syncingStates[`${account.accountId}-account`]}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '120px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {account.isTransactionSynced ? (
                                                <CheckmarkCircle24Filled className={classes.syncedIcon} />
                                            ) : (
                                                <SyncButton
                                                    onClick={() => handleSyncAccount(account.accountId, 'transactions')}
                                                    disabled={syncingStates[`${account.accountId}-transactions`]}
                                                    syncing={syncingStates[`${account.accountId}-transactions`]}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '120px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {account.isHistoricTransactionsEnabled ? (
                                                <CheckmarkCircle24Filled className={classes.syncedIcon} />
                                            ) : (
                                                <SyncButton
                                                    onClick={() => handleSyncAccount(account.accountId, 'extended')}
                                                    disabled={syncingStates[`${account.accountId}-extended`]}
                                                    syncing={syncingStates[`${account.accountId}-extended`]}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '100px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <span className={account.isTransactionSynced ? classes.synced : classes.notSynced}>
                                                {account.isTransactionSynced ? 'Synced' : 'Not Synced'}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ width: '48px' }}>
                                        <div className={classes.menuContainer}>
                                            <Menu>
                                                <MenuTrigger>
                                                    <Button
                                                        appearance="subtle"
                                                        className={classes.menuButton}
                                                        icon={<MoreVerticalRegular />}
                                                    />
                                                </MenuTrigger>
                                                <MenuPopover>
                                                    <MenuList>
                                                        <MenuItem
                                                            className={`${classes.menuItem} ${classes.deleteMenuItem}`}
                                                            onClick={() => toggleAccountVisibility(account.accountId)}
                                                        >
                                                            <span className={classes.menuIcon}>
                                                                <DeleteRegular />
                                                            </span>
                                                            Delete Account
                                                        </MenuItem>
                                                    </MenuList>
                                                </MenuPopover>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Widget>
        );
    };

    LinkedAccounts.defaultProps = {
        selectedAccounts: [],
        onRequestRefresh: () => { },
    };


export default LinkedAccounts;