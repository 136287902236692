import * as React from "react";
import { makeStyles, Button, tokens } from "@fluentui/react-components";
import {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Body1,
    Caption1,
    Text,
    Title1,
  } from "@fluentui/react-components";

const useClasses = makeStyles({
    widgetCard: {
        margin: "0px",
        padding: "0px",
    },
    widgetHeader: {
        padding: "5px",
        backgroundColor: tokens.colorBrandBackground3Static,
        color: tokens.colorNeutralForegroundStaticInverted,
    },
    div: {
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "90%",
    },
    widgetFooter: {
        height: "40px",
        padding: "10px",
    },
  });

export const Widget = ({image, title, caption, widgetClass, children}) => {
    const classes = useClasses();
return (
    <Card className={widgetClass}>
        <CardHeader className={classes.widgetHeader} image={image} header={<Body1>{title}</Body1>} />
        <div className={classes.div}>
            {children}
        </div>
        <CardFooter className={classes.widgetFooter}><Caption1>{caption}</Caption1></CardFooter>
    </Card>
)};